import { Alert, AlertTitle, Box, Container, FormControl, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Loader from "../CommonParts/Loader";
import { useAppContext } from "../../Contexts/AppContext";
import { Functions } from "../../Utils/Functions";
import GridDisplay from './Parts/GridDisplay';
import { useNavigationType } from 'react-router-dom';

export default function PurchasedMcaList() {
    const { Actions, Reducer, LanguageResponsiveMessages, ApiErrorMessages } = useAppContext();
    const type = useNavigationType();

    const itemsPerPage = 12;
    const maximumPages = Math.ceil(Reducer.myArtList.length / itemsPerPage);

    const [allMcaList, setAllMcaList] = useState([]);
    const [displayMcaList, setDisplayMcaList] = useState([]);
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        Actions.myArtListFetch({ UserID: Number(Reducer.userAuth.userId) });
        return () => {
            Actions.myArtListFetching(false, false);
        }
    }, []);

    useEffect(() => {
        if (type !== "POP") {
            Actions.setMyArtListHistory(1, 2);
            const sortMyArtList = [...Reducer.myArtList];
            setAllMcaList(sortMyArtList);
            setDisplayMcaList(sortMyArtList.slice(0, itemsPerPage));
        } else {
            const sortMyArtList = filterList(Reducer.myArtListHistory.conditions);
            setAllMcaList(sortMyArtList);
            setDisplayMcaList(
                sortMyArtList.slice(
                    ((Reducer.myArtListHistory.currentPage - 1) * itemsPerPage), Reducer.myArtListHistory.currentPage * itemsPerPage
                ));
        }
    }, [Reducer.myArtList]);

    function onPageChange(evn, index) {
        Actions.setMyArtListHistory(index);
        setDisplayMcaList(allMcaList.slice(((index - 1) * itemsPerPage), index * itemsPerPage));
    }

    function onSorttingOptionsChange(evn) {
        const sortDisplayMcaList = filterList(evn.target.value);
        Actions.setMyArtListHistory(1, evn.target.value);
        setDisplayMcaList(sortDisplayMcaList.slice(0, itemsPerPage));
        setAllMcaList(sortDisplayMcaList);
    }

    function filterList(selectCondition) {
        let sortDisplayMcaList = [...Reducer.myArtList];
        sortDisplayMcaList = sortDisplayMcaList
            .sort((a, b) => {
                const typeADate = a.AddedDateTime;
                const typeBDate = b.AddedDateTime;
                const typeATitle = a.getTitle(Reducer.language);
                const typeBTitle = b.getTitle(Reducer.language);
                const typeASubTitle = a.getSubTitle(Reducer.language);
                const typeBSubTitle = b.getSubTitle(Reducer.language);

                switch (selectCondition) {
                    case 1:
                        if (typeADate == typeBDate) {
                            return a.Id - b.Id
                        }
                        return typeADate <= typeBDate ? -1 : 1;
                    case 2:
                        if (typeADate == typeBDate) {
                            return a.Id - b.Id
                        }
                        return typeADate > typeBDate ? -1 : 1;
                    case 3:
                        return typeATitle <= typeBTitle ? -1 : 1;
                    case 4:
                        return typeATitle > typeBTitle ? -1 : 1;
                    case 5:
                        if (typeASubTitle == typeBSubTitle) {
                            return a.Id - b.Id
                        }
                        return typeASubTitle <= typeBSubTitle ? -1 : 1;
                    case 6:
                        if (typeASubTitle == typeBSubTitle) {
                            return a.Id - b.Id
                        }
                        return typeASubTitle > typeBSubTitle ? -1 : 1;
                    default:
                        return 0;
                }
            });
        return sortDisplayMcaList;
    }

    const errorMessage =
        Reducer.myArtListFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {ApiErrorMessages.apiMessage(Reducer.myArtListFetch.statusCode, "Reducer.myArtListFetch")}
            </Alert>
            : null;

    const sortingOptionsLabel = LanguageResponsiveMessages.sortingOptionsLabel;
    const sortingOptions = [
        { key: 1, value: 1, text: sortingOptionsLabel.purchaseDateAsc },
        { key: 2, value: 2, text: sortingOptionsLabel.purchaseDateDesc },
        { key: 3, value: 3, text: sortingOptionsLabel.artNameAsc },
        { key: 4, value: 4, text: sortingOptionsLabel.artNameDesc },
        { key: 5, value: 5, text: sortingOptionsLabel.artistNameAsc },
        { key: 6, value: 6, text: sortingOptionsLabel.artistNameDesc },
    ];

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (Reducer.myArtListFetch.loading) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [Reducer.myArtListFetch.loading]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={Reducer.myArtListFetch.loading && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        My Art List
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="sorting-condition-label">{sortingOptionsLabel.sortBy}</InputLabel>
                            <Select label={sortingOptionsLabel.sortBy} labelId="sorting-condition-label" value={Reducer.myArtListHistory.conditions} onChange={onSorttingOptionsChange}>
                                {sortingOptions.map(item => (
                                    <MenuItem key={item.key} value={item.value}>{item.text}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div >

                    {Reducer.myArtListFetch.loading
                        ? null
                        : displayMcaList.length === 0
                            ? <Typography variant="body1">
                                {LanguageResponsiveMessages.contentNotFound}
                            </Typography >
                            : <>
                                <GridDisplay mcaList={displayMcaList} />

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <Pagination
                                        count={maximumPages}
                                        size="large"
                                        page={Reducer.myArtListHistory.currentPage}
                                        onChange={onPageChange}
                                    />
                                </div>
                            </>
                    }
                </Box >
            </Container >
        </>
    );
}
