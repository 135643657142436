import { ButtonBase, Card, CardContent, CardMedia, Chip, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../Constants/Constants';
import { useAppContext } from "../../Contexts/AppContext";

export default function ImageCard({ imgCardObj }) {
    const { Reducer } = useAppContext();
    const navigate = useNavigate(); // 画面遷移
    const [stard, setStard] = useState(false);

    const cardMedia_title = ["image"];
    let label = "";
    let navigationUrl = ""
    if (imgCardObj.IsArtist) {
        cardMedia_title.push("artist");
        label = "Artist";
        navigationUrl = `/artist/${imgCardObj.Id}`;
    }
    else if (imgCardObj.IsEvent) {
        cardMedia_title.push("event");
        label = "Event";
        navigationUrl = `/event/${imgCardObj.Id}`;
    }
    else if (imgCardObj.IsArt) {
        cardMedia_title.push("art");
        label = "Art";
        navigationUrl = `/art/${imgCardObj.Id}`;
    }
    else if (imgCardObj.IsGoods) {
        cardMedia_title.push("goods");
        label = "Goods";
        navigationUrl = imgCardObj.GoodsURL.URL;
    }
    else if (imgCardObj.IsMyArt) {
        cardMedia_title.push("art");
        label = "Art";
        navigationUrl = `/account/purchasedMca/${imgCardObj.Id}`;
    }

    function onCardClick() {
        if (imgCardObj.IsGoods) {
            window.open(navigationUrl, '_blank');
        } else {
            navigate(navigationUrl, { state: { productId: imgCardObj.ProductId } });
        }
    }

    function handleImageError(e) {
        e.target.src = Constants.NO_IMAGE_URL;
    }

    return (
        <ButtonBase sx={{ borderRadius: '4px', width: '100%' }} onClick={onCardClick}>
            <Card sx={{ width: '100%' }}>
                <CardMedia
                    sx={{
                        maxWidth: '300px', maxHeight: '300px', padding: '10%', aspectRatio: "1 / 1",
                        backgroundColor: 'white',
                    }}
                >
                    <div style={{ height: '100%', width: '100%' }}>
                        <img
                            src={imgCardObj.ImageObjectURL ? imgCardObj.ImageObjectURL : Constants.NO_IMAGE_URL}
                            style={{ height: '100%', width: '100%', objectFit: 'contain', color: 'black' }}
                            title={cardMedia_title.join(" ")}
                            onError={handleImageError}
                        />
                    </div>
                </CardMedia>
                {/* <IconButton
                    aria-label="favorite" onClick={() => setStard(prev => !prev)}
                    sx={{ position: "absolute", top: 0, right: 0 }}
                >
                    {stard
                        ? <StarRoundedIcon fontSize="large"
                            sx={{ color: yellow[700], stroke: grey[300], strokeWidth: 1, strokeOpacity: 1 }} />
                        : <StarBorderRoundedIcon fontSize="large"
                            sx={{ color: grey[300] }} />}
                </IconButton> */}
                <CardContent>
                    <Typography variant="subtitle1" sx={{ textAlign: 'left' }} className='latter-half-omitted-1'>
                        {imgCardObj.getTitle(Reducer.language)}
                    </Typography>
                    <Stack direction="row" alignItems="flex-end" justifyContent="space-between" spacing={1}>
                        <Typography variant="body2" sx={{ textAlign: 'left' }} className='latter-half-omitted-1'>
                            {imgCardObj.getSubTitle(Reducer.language)}
                        </Typography>
                        <Chip variant="outlined" label={label} />
                    </Stack>
                </CardContent>
            </Card>
        </ButtonBase>
    );
}
