export default class UserInfoModel {
    constructor(dataObject = null) {
        this.UserID = 0;
        this.CognitoUserName = "";
        this.FirstName = "";
        this.LastName = "";
        this.BirthDate = "";
        this.Email = "";
        this.AddressLine1 = "";
        this.AddressLine2 = "";
        this.City = "";
        this.ProvinceCode = "";
        this.CountryCode = "";
        this.ZipCode = "";
        this.PhoneNumber = "";
        this.PasswordUpdateAt = "";

        if (dataObject !== null) {
            this.UserID = Number(dataObject.UserID);
            this.CognitoUserName = dataObject.CognitoUserName;
            this.FirstName = dataObject.FirstName;
            this.LastName = dataObject.LastName;
            this.BirthDate = dataObject.BirthDate;
            this.Email = dataObject.Email;
            this.AddressLine1 = dataObject.AddressLine1;
            this.AddressLine2 = dataObject.AddressLine2;
            this.City = dataObject.City;
            this.ProvinceCode = dataObject.ProvinceCode;
            this.CountryCode = dataObject.CountryCode;
            this.ZipCode = dataObject.ZipCode;
            this.PhoneNumber = dataObject.PhoneNumber;
            this.PasswordUpdateAt = dataObject.PasswordUpdateAt;
        }
    }
}
