import * as Constants from "../Constants/Constants";

export class ValidErrorMessages {
    constructor(lang) {
        this.Language = lang;
    }

    /**
     * @returns [0]は[1]の値として不正です。
     * @returns The value [0] is not valid for [1].
     */
    invalidValue({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `「${jaWords[0]}」は${jaWords[1]}の値として不正です。`
        } else {
            return `The value '${enWords[0]}' is not valid for ${enWords[1]}.`;
        }
    }

    /**
     * @returns [0]は入力必須です。
     * @returns [0] must be entered.
     */
    requiredValue({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}を入力してください。`
        } else {
            return `Please enter the ${enWords[0]} field.`;
        }
    }

    /**
     * @returns 現在と同じ[0]は設定できません。
     * @returns The same [0] cannot be set as the current one.
     */
    sameValue({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `現在と同じ${jaWords[0]}は設定できません。`
        } else {
            return `The same ${enWords[0]} cannot be set as the current one.`;
        }
    }

    /**
     * @returns [0]を含む[1]を入力してください。
     * @returns Please enter a [1] that includes [0].
     */
    notEntered({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}を含む${jaWords[1]}を入力してください。`;
        } else {
            return `Please enter a ${enWords[1]} that includes ${enWords[0]}.`;
        }
    }

    /**
     * @returns [0]文字以上の[1]を入力してください。
     * @returns Please enter a [1] of at least [0] character.
     */
    minimumCharacters({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}文字以上の${jaWords[1]}を入力してください。`;
        } else {
            return `Please enter a ${enWords[1]} of at least ${enWords[0]} character.`;
        }
    }

    /**
     * @returns [0]の先頭または末尾にスペースが入力されています。削除してください。
     * @returns A space is entered at the beginning or end of the [0]. Please delete it.
     */
    spaceError({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}の先頭または末尾にスペースが入力されています。削除してください。`;
        } else {
            return `A space is entered at the beginning or end of the ${enWords[0]}. Please delete it.`;
        }
    }

    /**
     * @returns [0]が異なります。
     * @returns [0] is different.
     */
    differentInputValues({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}が異なります。`;
        } else {
            return `${enWords[0]} is different.`;
        }
    }

    /**
     * @returns どちらか片方だけの入力は無効です。両方入力してください。
     * @returns Entering only one of them is invalid. Please enter both.
     */
    bothRequiredValue({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "どちらか片方だけの入力は無効です。両方入力してください。";
        } else {
            return "Entering only one of them is invalid. Please enter both.";
        }
    }

    /**
     * @returns [0]桁の半角数字を入力してください。
     * @returns Please enter a [0]-digit, half-width number.
     */
    invalidZipValue({ jaWords, enWords }) {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return `${jaWords[0]}桁の半角数字を入力してください。`
        } else {
            return `Please enter a ${enWords[0]}-digit, half-width number.`
        }
    }

    /**
     * メールアドレス変更画面
     */
    editEmailValidationError({ type, jaWords, enWords }) {
        switch (type) {
            case "requiredValue":
                return this.requiredValue({ jaWords, enWords });

            case "sameValue":
                return this.sameValue({ jaWords, enWords });

            default:
                return;
        }
    }

    /**
     * 確認コード入力画面
     */
    editEmailCheckAuthcodeValidationError({ type, jaWords, enWords }) {
        switch (type) {
            case "requiredValue":
                return this.requiredValue({ jaWords, enWords });

            case "invalidValue":
                return this.invalidValue({ jaWords, enWords });

            default:
                return;
        }
    }

    /**
     * パスワード変更画面
     */
    passwordValidationError({ type, jaWords, enWords }) {
        switch (type) {
            case "requiredValue":
                return this.requiredValue({ jaWords, enWords });

            case "notEntered":
                return this.notEntered({ jaWords, enWords });

            case "minimumCharacters":
                return this.minimumCharacters({ jaWords, enWords });

            case "spaceError":
                return this.spaceError({ jaWords, enWords });

            case "differentInputValues":
                return this.differentInputValues({ jaWords, enWords });

            default:
                return;
        }
    }

    zipCodeValidationError({ type, jaWords, enWords }) {
        switch (type) {
            case "digitError":
                return this.invalidZipValue({ jaWords, enWords });

            case "notEntered":
                return this.bothRequiredValue({ jaWords, enWords });

            default:
                return;
        }
    }

    nameValidationError({ type, jaWords, enWords }) {
        switch (type) {
            case "requiredValue":
                return this.requiredValue({ jaWords, enWords });

            case "invalidError":
                return this.invalidValue({ jaWords, enWords });

            default:
                return;
        }
    }
}

