import * as Constants from "../Constants/Constants";
import { Link } from '@mui/material';

export class LanguageResponsiveMessages {
    constructor(lang) {
        this.Language = lang;
    }

    // メッセージ
    // ログイン画面
    get signinStatusError() {
        const login_url = "https://auth.dev-mypage.gaaat.com/oauth2/authorize?client_id=6nsvgt16v7291figjssilhrc37&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https%3A%2F%2Fdev-mypage.gaaat.com";
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return <>
                ログインステータスが無効です。<Link href={login_url} color="inherit">再ログイン</Link>してください。
            </>
        } else {
            return <>
                Invalid login status. Please <Link href={login_url} color="inherit">login</Link> again.
            </>
        }
    }

    // EditComplete.js
    get finishReconfiguration() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "の再設定を完了しました。";
        } else {
            return "reset completed.";
        }
    }

    get backToAccountInfo() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "アカウント情報へ戻る";
        } else {
            return "Back to account information.";
        }
    }

    // EditEmail.js
    get sendVerificationCode() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return <>
                GAAAT IDを再設定するための確認コードが記載されたメールを送信します。<br />
                新GAAAT ID(メールアドレス)を入力して「送信」をクリックしてください。
            </>
        } else {
            return <>
                You will receive an email with a confirmation code to reset your GAAAT ID.<br />
                Enter your new GAAAT ID(email address) and click on ‘Send’.
            </>
        }
    }

    get contentNotFound() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "コンテンツが見つかりませんでした。";
        } else {
            return "Content not found.";
        }
    }

    get agreeConditionsOfUse() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return <><Link href="#" color="inherit">利用規約</Link>に同意します</>
        } else {
            return <>I agree to the <Link href="#" color="inherit">conditions of use</Link></>
        }
    }

    get notRobot() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "私はロボットではありません。"
        } else {
            return "I'm not a robot. "
        }
    }

    get sendConfirmationURL() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "※新GAAAT ID宛に確認用URLを送信します。"
        } else {
            return "*A confirmation URL will be sent to the new GAAAT ID. "
        }
    }

    get sendResetURL() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "※新しいパスワードを設定します。"
        } else {
            return "*Set a new password."
        }
    }

    get birthDateCannotBeChanged() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "※登録後は変更できません。";
        } else {
            return "*Once registered, the birth date cannot be changed.";
        }
    }

    // ラベル
    get commonLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                filters: "絞り込み条件",
                art: "アート",
                artist: "アーティスト",
                event: "イベント",
            }
        } else {
            obj = {
                filters: "Filters",
                art: "Art",
                artist: "Artist",
                event: "Event",
            }
        }
        return obj;
    }

    get commonButtonLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                confirm: "確認",
                save: "保存",
                edit: "変更",
                back: "戻る",
                send: "送信",
                skip: "入力をスキップ",
            }
        } else {
            obj = {
                confirm: "Confirm",
                save: "Save",
                edit: "Edit",
                back: "Back",
                send: "Send",
                skipInput: "Skip Input",
            }
        }
        return obj;
    }

    get loginInfo() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                loginId: "GAAAT ID(メールアドレス)",
                newLoginId: "新GAAAT ID(メールアドレス)",
                password: "パスワード",
                currentPassword: "現在のパスワード",
                newPassword: "新パスワード",
                reEnteringPassword: "新パスワード(再入力)",
            }
        } else {
            obj = {
                loginId: "GAAAT ID(email address)",
                newLoginId: "new GAAAT ID(email address)",
                password: "Password",
                currentPassword: "Current Password",
                newPassword: "New Password",
                reEnteringPassword: "New Password(re-entering)",
            }
        }
        return obj;
    }

    // EditEmailCheckAuthcode.js
    get editEmailCheckAuthcodeLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                sendToMailAddress: "送信先メールアドレス",
                verificationCode: "確認コード",
            }
        } else {
            obj = {
                sendToMailAddress: "Send to email address",
                digitNumber: "Please enter a 6-digit number",
                verificationCode: "Verification Code",
            }
        }
        return obj;
    }

    get followLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                unfollow: "フォロー解除",
                follow: "フォロー登録",
            }
        } else {
            obj = {
                unfollow: "Unfollow",
                follow: "Follow",
            }
        }
        return obj;
    }

    get footerLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                conditionsOfUse: "利用規約",
                privacyPolicy: "プライバシーポリシー",
            }
        } else {
            obj = {
                conditionsOfUse: "Conditions of Use",
                privacyPolicy: "Privacy Policy",
            }
        }
        return obj;
    }

    get headerLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                userInformation: "アカウント情報",
                followList: "フォロー一覧",
                myArtList: "保有アート一覧",
                signOut: "ログアウト",
                searchPlaceholder: "アート・アーティスト・イベントを検索"
            }
        } else {
            obj = {
                userInformation: "User Information",
                followList: "Follow List",
                myArtList: "My Art List",
                signOut: "Sign Out",
                searchPlaceholder: "Search for art, artists and events"
            }
        }
        return obj;
    }

    //PurchasedMcaDetailedInformation.js
    get artInfoLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                sizeLabel: "サイズ(縦x横) mm",
                manufacturingDateLabel: "製造年月日",
                techniqueLabel: "技法",
                totalEditionsLabel: "エディション数",
                editionNumber: "エディション番号",
                pictureFramingLabel: "額装",
                signatureLabel: "サイン",
                productPage: "商品ページ",
                purchasePrice: "価格",
            }
        } else {
            obj = {
                sizeLabel: "Size (H x W) mm",
                manufacturingDateLabel: "Manufacturing Date",
                techniqueLabel: "Technique",
                totalEditionsLabel: "Total Editions",
                editionNumber: "Editions Number",
                pictureFramingLabel: "Picture Framing",
                signatureLabel: "Signature",
                productPage: "Product Page",
                purchasePrice: "Price",
            }
        }
        return obj;
    }

    get sortingOptionsLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                sortBy: "並び替え条件",
                purchaseDateAsc: "購入日（昇順）",
                purchaseDateDesc: "購入日（降順）",
                artNameAsc: "アート名（昇順）",
                artNameDesc: "アート名（降順）",
                artistNameAsc: "アーティスト名（昇順）",
                artistNameDesc: "アーティスト名（降順）",
            }
        } else {
            obj = {
                sortBy: "Sort By",
                purchaseDateAsc: "Purchase Date(asc)",
                purchaseDateDesc: "Purchase Date(desc)",
                artNameAsc: "Art Name(asc)",
                artNameDesc: "Art Name(desc)",
                artistNameAsc: "Artist Name(asc)",
                artistNameDesc: "Artist Name(desc)",
            }
        }
        return obj;
    }

    // PurchasedMcaRightsInformation.js
    get purchasedMcaRightsInformationLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                privilegeType: "権利種別",
                period: "期間",
                status: "状況",
                history: "履歴",
            }
        } else {
            obj = {
                privilegeType: "Privilege Type",
                period: "Period",
                status: "Status",
                history: "History",
            }
        }
        return obj;
    }

    get userInformationLabel() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                birthDate: "生年月日",
                country: "国 / 地域",
                fullName: "氏名",
                lastName: "姓",
                firstName: "名",
                phoneNumber: "電話番号",
                zipCode: "郵便番号",
                prefecture: "都道府県",
                city: "市区町村",
                cityBlock: "丁目 / 番地 / 号",
                buildingName: "建物名 / 会社名 / 部屋番号",
            }
        } else {
            obj = {
                birthDate: "Birth Date",
                country: "Country / Region",
                fullName: "Full name",
                lastName: "Last name",
                firstName: "First name",
                phoneNumber: "Phone number",
                zipCode: "Postal code",
                prefecture: "Prefecture",
                city: "City",
                cityBlock: "Street address",
                buildingName: "Building / Company / Room no.",
            }
        }
        return obj;
    }

    get inputNotesTitle() {
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return "パスワードの設定ルール"
        } else {
            return "Password setting rules"
        }
    }

    get notesOnPasswordEntry() {
        let obj;
        if (this.Language == Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            obj = {
                lowerCaseLetter: "小文字のアルファベットを含めてください。",
                upperCaseLetter: "大文字のアルファベットを含めてください。",
                number: "数字を含めてください。",
                characterCount: "8字以上のパスワードを設定してください。",
                specialCharacter: "特殊文字またはスペースを含めてください。",
                space: "先頭または末尾にスペースを含めないでください。",
            }
        } else {
            obj = {
                lowerCaseLetter: "Password must contain a lower case letter.",
                upperCaseLetter: "Password must contain an upper case letter.",
                number: "Password must contain a number.",
                characterCount: "Password must contain at least 8 characters.",
                specialCharacter: "Password must contain a special character or a space.",
                space: "Password must not contain a leading or trailing space.",
            }
        }
        return obj;
    }
}
