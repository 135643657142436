import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../../../Constants/Constants";
import { useAppContext } from "../../../Contexts/AppContext";
import { Functions } from "../../../Utils/Functions";

export default function PurchasedMcaRightsInformation({ privilegeInfo, privilegePermissionHistory }) {
    // const [buttonStates, setButtonStates] = useState({ 1: "", 2: true, 3: false });

    // const toggleButtonText = (buttonId) => {
    //     setButtonStates((prevState) => ({
    //         ...prevState,
    //         [buttonId]: !prevState[buttonId],
    //     }))
    // }
    const { Reducer, LanguageResponsiveMessages } = useAppContext();

    const historyDateCellSx = {
        width: '55px'
    };

    const periodOfPrivilege = privilegeInfo.map(item => {
        let date;
        if (item.PrivilegeStartDate) {
            if (item.PrivilegeEndDate) {
                date = Functions.formatDateBySlash(item.PrivilegeStartDate, Reducer.language) + " ~ " + Functions.formatDateBySlash(item.PrivilegeEndDate, Reducer.language);
            } else {
                date = Functions.formatDateBySlash(item.PrivilegeStartDate, Reducer.language) + " ~ ";
            }
        } else if (item.PrivilegeEndDate) {
            date = " ~ " + Functions.formatDateBySlash(item.PrivilegeEndDate, Reducer.language);

        } else {
            date = "-";
        }

        return <TableRow key={item.Id}>
            <TableCell className='rights-body'>{item.getPrivilegeDetail(Reducer.language)}</TableCell>
            <TableCell className='rights-body'>{item.getArtistPermissionStatus(Reducer.language)}</TableCell>
            <TableCell className='rights-body'>{date}</TableCell>
            {/* <TableCell className='rights-body' align='center'>
                    {item.application
                        ? <Button color="inherit" variant="outlined" size="small" id={item.id} onClick={() => toggleButtonText(item.id)}>
                            {buttonStates[item.id] ? "申請" : "申請中"}
                        </Button>
                    : "-"}
                </TableCell> */}
        </TableRow>
    })

    const label = LanguageResponsiveMessages.purchasedMcaRightsInformationLabel;

    return (
        <Box component={Paper}>
            <TableContainer sx={{ mb: 2 }}>
                <Table className="rights-table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='rights-header'>{label.privilegeType}</TableCell>
                            <TableCell className='rights-header'>{label.status}</TableCell>
                            <TableCell className='rights-header'>{label.period}</TableCell>
                            {/* <TableCell className='rights-header' align='center'>利用申請</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {periodOfPrivilege}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider variant="middle" />
            <TableContainer>
                <Table className="rights-table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='history-header' colSpan={2}>{label.history}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {privilegePermissionHistory.map((item, index) => {
                            return <TableRow key={index}>
                                <TableCell sx={historyDateCellSx} className='history-body'>
                                    {Functions.formatDateBySlash(item.PermissionDateTime, Reducer.language)}
                                </TableCell>
                                <TableCell className='history-body'>
                                    {Reducer.language == JA ?
                                        item.getPermitterType(Reducer.language) + "が「" + item.getContentsName(Reducer.language) + "」の" + item.getPrivilegeDetail(Reducer.language) + "を" + item.getPermissionStatus(Reducer.language) + "にしました。"
                                        : item.getPermitterType(Reducer.language) + " has changed " + item.getPrivilegeDetail(Reducer.language) + " of '" + item.getContentsName(Reducer.language) + "' to " + item.getPermissionStatus(Reducer.language) + "."
                                    }
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
