import { Alert, AlertTitle, Box, Container, Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Constants from '../../Constants/Constants';
import { useAppContext } from "../../Contexts/AppContext";
import AppConfig from '../../Utils/AppConfig';
import DetailInformation from './Parts/DetailInformation';
import ImageScroller from '../CommonParts/ImageScroller';
import Loader from "../CommonParts/Loader";

const apiKey = process.env.REACT_APP_API_KEY;

export default function EventDetail() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const { id } = useParams();
    const [followStatusFetch, setFollowStatusFetch] = useState({ loading: false, loadingError: false, statusCode: 0 });
    const [isTimeout, setIsTimeout] = useState(false);

    const eventInfo = useMemo(() => {
        return Reducer.eventInfoList.find(x => x.EventID == id);
    }, [Reducer.eventInfoList, id]);

    useEffect(() => {
        Actions.eventInfoFetch({ EventID: Number(id) });
        Actions.exhibitArtListFetch({ EventID: Number(id), Count: 10 });
        Actions.exhibitArtistListFetch({ EventID: Number(id), Count: 10 });
        fetchFollowStatus({ UserID: Number(Reducer.userAuth.userId), ContentsType: Constants.FOLLOW_CONTENTS_TYPE_EVENT, ContentsID: Number(id) });
        return () => {
            Actions.eventInfoFetching(false, false);
            Actions.exhibitArtListFetching(false, false);
            Actions.exhibitArtistListFetching(false, false);
            Actions.insertFollowContentsFetching(false, false);
            Actions.deleteFollowContentsFetching(false, false);
        }
    }, []);

    function fetchFollowStatus(postData) {
        setFollowStatusFetch(prev => ({ ...prev, loading: true, loadingError: false, statusCode: 0 }));

        fetch(AppConfig.GetFollowStatus, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                setFollowStatusFetch(prev => ({ ...prev, loading: false }));
                return response;
            })
            .then(response => response.json())
            .then(status => {
                if (status.FollowStatus) {
                    Actions.addFollowContentsList(postData);
                } else {
                    Actions.deleteFollowContentsList(postData);
                }
            })
            .catch((e) => {
                setFollowStatusFetch(prev => ({ ...prev, loading: false, loadingError: true, statusCode: Number(e.StatusCode) }));
            });
    }

    const followStatus = Reducer.followContentsList.some(x => x.Id == id && x.IsEvent);

    const loadingFlagKeys = ["eventInfoFetch", "exhibitArtListFetch", "exhibitArtistListFetch", "insertFollowContents", "deleteFollowContents"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag || followStatusFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys
                    .filter(key => Reducer[key].loadingError)
                    .map(key =>
                        <>
                            {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                        </>
                    )}
                {followStatusFetch.statusCode ?
                    ApiErrorMessages.apiMessage(followStatusFetch.statusCode, "EventDetail.fetchFollowStatus")
                    : null
                }
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (loadingFlag) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [loadingFlag]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={(loadingFlag || followStatusFetch.loading) && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Event
                    </Typography>
                    {eventInfo
                        ? <DetailInformation
                            event
                            img={eventInfo.EventImage?.ImageObjectURL}
                            title={eventInfo.getEventTitle(Reducer.language)}
                            info={eventInfo.getEventDescription(Reducer.language)}
                            urlList={eventInfo.EventURL}
                            followStatus={followStatus}
                        />
                        : null
                    }
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Arts
                    </Typography>
                    {Reducer.exhibitArtList.length === 0
                        ? null
                        : <ImageScroller imgCardList={Reducer.exhibitArtList} />
                    }
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Artists
                    </Typography>
                    {Reducer.exhibitArtistList.length === 0
                        ? null
                        : <ImageScroller imgCardList={Reducer.exhibitArtistList} />
                    }
                </Box>
            </Container>
        </>
    );
}
