import { Alert, AlertTitle, Box, Container, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from "../CommonParts/Loader";
import { useAppContext } from "../../Contexts/AppContext";
import AppConfig from "../../Utils/AppConfig";
import PurchasedMcaDetailedInformation from './Parts/PurchasedMcaDetailedInformation';
import * as Constants from '../../Constants/Constants';

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlag = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

export default function McaDetail() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const { id } = useParams();
    const [followStatusFetch, setFollowStatusFetch] = useState(initialLoadingFlag);
    const [isTimeout, setIsTimeout] = useState(false);

    const artInfo = useMemo(() => {
        return Reducer.artInfoList.find(x => x.Id == id);
    }, [Reducer.artInfoList, id]);

    useEffect(() => {
        Actions.artInfoFetch({ ProductID: Number(id) });
        fetchFollowStatus({ UserID: Number(Reducer.userAuth.userId), ContentsType: Constants.FOLLOW_CONTENTS_TYPE_PRODUCT, ContentsID: Number(id) });
        return () => {
            Actions.artInfoFetching(false, false);
            Actions.insertFollowContentsFetching(false, false);
            Actions.deleteFollowContentsFetching(false, false);
        }
    }, []);

    function fetchFollowStatus(postData) {
        setFollowStatusFetch(prev => ({ ...prev, loading: true, loadingError: false, statusCode: 0 }));
        fetch(AppConfig.GetFollowStatus, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        }).then(response => {
            if (!response.ok) {
                return response.json()
                    .then(function (err) {
                        throw err
                    });
            }
            setFollowStatusFetch(prev => ({ ...prev, loading: false }));
            return response;
        })
            .then(response => response.json())
            .then(status => {
                if (status.FollowStatus) {
                    Actions.addFollowContentsList(postData);
                } else {
                    Actions.deleteFollowContentsList(postData);
                }
            })
            .catch((e) => {
                setFollowStatusFetch(prev => ({
                    ...prev, loading: false, loadingError: true, statusCode: Number(e.StatusCode)
                }));
            });
    }

    const loadingFlagKeys = ["insertFollowContents", "artInfoFetch", "deleteFollowContents"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag || followStatusFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys
                    .filter(key => Reducer[key].loadingError)
                    .map(key =>
                        <>
                            {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                        </>
                    )}
                {followStatusFetch.statusCode ?
                    ApiErrorMessages.apiMessage(followStatusFetch.statusCode, "McaDetail.fetchFollowStatus")
                    : null
                }
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (loadingFlag) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [loadingFlag]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={(loadingFlag || followStatusFetch.loading) && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Art
                    </Typography>
                    {artInfo
                        ? <PurchasedMcaDetailedInformation
                            mca
                            imgDataList={artInfo.ProductImage}
                            title={artInfo.getProductTitle(Reducer.language)}
                            artist={artInfo.getArtistDisplayName(Reducer.language)}
                            //orderPlaced={orderPlaced}
                            info={artInfo.gatProductDescription(Reducer.language)}
                            //size={size}
                            //manufacturingDate={manufacturingDate}
                            //technique={technique}
                            totalEditions={artInfo.TotalEditions}
                            //pictureFraming={pictureFraming}
                            //signature={signature}
                            productHref={artInfo.ProductURL[0]}
                        />
                        : null
                    }
                </Box>
            </Container>
        </>
    );
}

