import { Button, Link as MuiLink } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link as RouterLink } from "react-router-dom";
import { useAppContext } from "../../Contexts/AppContext";

export function CommonButton({ onClick, sx = {}, children }) {
    return (
        <Button className="common-button" sx={sx} variant="outlined" onClick={onClick}>
            {children}
        </Button>
    );
}

/**
 * 外部サイトへの遷移ができるボタン
 * @param {{label: string, href: string, margin: string}}
 */
export function ButtonToExternalSites({ label, href, margin = "", onClick }) {
    return (
        <MuiLink href={href} target="_blank" rel="noopener noreferrer" color="inherit" style={{ margin: margin }}>
            <CommonButton onClick={onClick} sx={{ textTransform: 'none' }}>
                {label}
            </CommonButton>
        </MuiLink>
    );
}

/**
 * React Routerを使った画面遷移ができるボタン
 * @param {{label: string, to: string, state:any,  margin: string}}
 */
export function ButtonToOtherPage({ label, to = "/", state = { test: "test" }, margin = "", onClick }) {
    return (
        <RouterLink to={to} state={state} style={{ color: "inherit", margin: margin }}>
            <CommonButton onClick={onClick} sx={{ textTransform: 'none' }}>
                {label}
            </CommonButton>
        </RouterLink>
    );
}

/**
 * お気に入りの登録/解除ができるボタン
 * @param {{margin: string}}
 */
export function ButtonForFavorite({ margin = "", followStatus = false, onFollowClick, onUnfollowClick }) {
    const { LanguageResponsiveMessages } = useAppContext();
    function handleClick() {
        if (followStatus) {
            onUnfollowClick();
        } else {
            onFollowClick();
        }
    }

    const style = { margin: margin, textTransform: 'none' };
    if (followStatus) {
        style.backgroundColor = `${grey[800]} !important`;
    }

    const followLabel = LanguageResponsiveMessages.followLabel;

    return (
        <CommonButton onClick={handleClick} sx={style}>
            {followStatus ? followLabel.unfollow : followLabel.follow}
        </CommonButton>
    );
}
