export default class GeneralCode {
    constructor(dataObject = null) {
        this.Code = "";
        this.JPCodeDescription = "";
        this.ENCodeDescription = "";
        this.SortOrder = 0;

        if (dataObject) {
            this.Code = dataObject.Code;
            this.JPCodeDescription = dataObject.JPCodeDescription;
            this.ENCodeDescription = dataObject.ENCodeDescription;
            this.SortOrder = Number(dataObject.SortOrder);
        }
    }
}
