import { Alert, AlertTitle, Box, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonButton } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';
import { useAppContext } from "../../Contexts/AppContext";
import AppConfig from "../../Utils/AppConfig";
import { Functions } from '../../Utils/Functions';
import Loader from '../CommonParts/Loader';

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlags = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

// バリデーションエラー(エラーを表示する入力欄分のリストを作っておく)
const initialValidError = {
    emailError: []
};

export default function EditEmail() {
    const { Reducer, LanguageResponsiveMessages, ValidErrorMessages, ApiErrorMessages } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentEmail, newEmail } = location.state;

    const [inputEmail, setInputEmail] = useState(newEmail);
    const [loadingFlags, setLoadingFlags] = useState(initialLoadingFlags);
    const [validErrors, setValidErrors] = useState(initialValidError);

    function onValueChange(event) {
        setInputEmail(event.target.value);
    }

    function validCheck() {
        let emailError = [];
        // 必須入力チェック
        if (Functions.isEmpty(inputEmail)) {
            emailError.push({
                id: 0,
                type: "requiredValue",
                jaWords: ["メールアドレス"],
                enWords: ["Email address"],
            });
        } else {
            // 入力値チェック
            if (inputEmail === currentEmail) {
                emailError.push({
                    id: 1,
                    type: "sameValue",
                    jaWords: ["メールアドレス"],
                    enWords: ["Email address"],
                });
            }
        }

        let errors = {};
        if (emailError.length > 0) {
            Object.assign(errors, { emailError });
        }

        if (Object.keys(errors).length > 0) {
            setValidErrors(errors);
            return false;
        }
        return true;
    }

    function onSendEmail() {
        if (!validCheck()) {
            return;
        }

        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            new_email: inputEmail,
        };

        setLoadingFlags({ loading: true, loadingError: false, statusCode: 0 });
        fetch(AppConfig.ChangeEmailAddress, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(err => {
                            throw err
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(json => {
                if (json.statusCode == 200) {
                    navigate("/account/userInfo/edit/email/checkAuthcode", { state: { currentEmail: currentEmail, newEmail: inputEmail } });
                } else {
                    setLoadingFlags({ loading: false, loadingError: true, statusCode: json.statusCode });
                }
            })
            .catch(e => {
                setLoadingFlags({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
            });
    }

    const errorMessage =
        loadingFlags.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {ApiErrorMessages.apiMessage(loadingFlags.statusCode, "EditEmail.onSendEmail")}
            </Alert>
            : null;

    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <>
            <Container maxWidth="lg" >
                <Loader open={loadingFlags.loading} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Reset GAAAT ID
                    </Typography>
                    <Paper sx={{ py: 5, px: 2 }} >
                        <Box component="form" sx={{ mx: { xs: 1, sm: 4 } }} >
                            <Grid container spacing={1} direction="column" justifyContent="center">
                                <Box sx={{ mb: 1 }}>
                                    <LabelValuePair label={loginInfoLabel.loginId} value={currentEmail} />
                                </Box>

                                <Divider />

                                <Typography variant="body1" component="p" sx={{ mt: 2, mb: 3 }} color="text.secondary">
                                    {LanguageResponsiveMessages.sendVerificationCode}
                                </Typography>
                                <Box component="div" sx={{ mb: 2 }} >
                                    {validErrors.emailError.map(obj =>
                                        <FormHelperText key={obj.id} sx={{ mb: 1 }} error={!Functions.isEmpty(validErrors.emailError)}>
                                            {ValidErrorMessages.editEmailValidationError(obj)}
                                        </FormHelperText>
                                    )}
                                    <FormControl sx={{ width: '100%' }} variant="outlined" error={!Functions.isEmpty(validErrors.emailError)} required>
                                        <InputLabel htmlFor="outlined-login-email">{loginInfoLabel.newLoginId}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-login-email"
                                            type="email"
                                            label={loginInfoLabel.newLoginId}
                                            value={inputEmail}
                                            onChange={onValueChange}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CommonButton onClick={onSendEmail} sx={{ textTransform: 'none' }}>{commonButtonLabel.send}</CommonButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper >
                </Box >
            </Container >
        </>
    );
}
