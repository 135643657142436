import { Alert, AlertTitle, Box, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { CommonButton } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';
import { useAppContext } from '../../Contexts/AppContext';
import AppConfig from "../../Utils/AppConfig";
import { Functions } from '../../Utils/Functions';
import Loader from '../CommonParts/Loader';

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlags = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

// バリデーションエラー(エラーを表示する入力欄分のリストを作っておく)
const initialValidError = {
    authcodeError: []
};

export default function EditEmailCheckAuthcode() {
    const { Reducer, LanguageResponsiveMessages, ValidErrorMessages, ApiErrorMessages } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentEmail, newEmail } = location.state;

    const [authcode, setAuthcode] = useState(null);
    const [updateEmailAddressFetch, setUpdateEmailAddressFetch] = useState(initialLoadingFlags);
    const [checkEmailAddressFetch, setCheckEmailAddressFetch] = useState(initialLoadingFlags);
    const [checkCompleted, setCheckCompleted] = useState(false);
    const [validErrors, setValidErrors] = useState(initialValidError);

    useEffect(() => {
        if (checkCompleted) {
            const postData = {
                UserID: Number(Reducer.userAuth.userId),
                userSub: Reducer.userAuth.userSub,
                Email: newEmail
            };
            fetch(AppConfig.UpdateEmailAddress, {
                method: "post",
                headers: {
                    "x-api-key": apiKey,
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json()
                            .then(function (err) {
                                throw err
                            });
                    }
                    return response;
                })
                .then(response => response.json())
                .then(json => {
                    if (json.statusCode == 200) {
                        navigate("/account/userInfo/edit/complete",
                            { state: { message: LanguageResponsiveMessages.loginInfo.loginId } }
                        );
                    } else {
                        setUpdateEmailAddressFetch({ loading: false, loadingError: true, statusCode: json.statusCode });
                    }
                })
                .catch((e) => {
                    setUpdateEmailAddressFetch({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
                });
        }
    }, [checkCompleted])

    function onValueChange(event) {
        setAuthcode(event.target.value);
    }

    function onBack() {
        navigate("/account/userInfo/edit/email", { state: { currentEmail: currentEmail, newEmail: newEmail } });
    }

    function validCheck() {
        let authcodeError = [];
        // 必須入力チェック
        if (Functions.isEmpty(authcode)) {
            authcodeError.push({
                id: 0,
                type: "requiredValue",
                jaWords: ["確認コード"],
                enWords: ["Verification Code"],
            });
        } else {
            // 入力値チェック
            if (authcode.match(/[0-9]+/g) != authcode) {
                authcodeError.push({
                    id: 1,
                    type: "invalidValue",
                    jaWords: [authcode, "確認コード"],
                    enWords: [authcode, "Verification Code"],
                });
            }
        }

        let errors = {};
        if (authcodeError.length > 0) {
            Object.assign(errors, { authcodeError });
        }

        if (Object.keys(errors).length > 0) {
            setValidErrors(errors);
            return false;
        }
        return true;
    }

    function onSendAuthcode(event) {
        if (!validCheck()) {
            return;
        }

        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            username: Reducer.userAuth.userSub,
            confirmation_code: Number(authcode),
            new_email: newEmail
        }

        setCheckEmailAddressFetch({ loading: true, loadingError: false, statusCode: 0 });
        fetch(AppConfig.CheckEmailAddress, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(err => {
                            throw err
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(json => {
                if (json.statusCode == 200) {
                    setCheckEmailAddressFetch(initialLoadingFlags);
                    setCheckCompleted(true);
                } else {
                    setCheckEmailAddressFetch({ loading: false, loadingError: true, statusCode: json.statusCode });
                }
            })
            .catch(e => {
                setCheckEmailAddressFetch({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
            });
    }

    const errorMessage =
        updateEmailAddressFetch.loadingError || checkEmailAddressFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {updateEmailAddressFetch.statusCode ?
                    ApiErrorMessages.apiMessage(updateEmailAddressFetch.statusCode, "EditEmailCheckAuthcode.updateEmailAddress")
                    : null
                }
                {checkEmailAddressFetch.statusCode ?
                    ApiErrorMessages.apiMessage(checkEmailAddressFetch.statusCode, "EditEmailCheckAuthcode.onSendAuthcode")
                    : null
                }
            </Alert>
            : null;

    const label = LanguageResponsiveMessages.editEmailCheckAuthcodeLabel;
    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;

    return (
        <>
            <Container maxWidth="lg" >
                <Loader open={checkEmailAddressFetch.loading || updateEmailAddressFetch.loading} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Enter Verification Code
                    </Typography>
                    <Paper sx={{ py: 5, px: 2 }} >
                        <Box component="form" sx={{ mx: { xs: 1, sm: 4 } }} >
                            <Grid container spacing={1} direction="column" justifyContent="center">
                                <Box sx={{ mb: 1 }} >
                                    <LabelValuePair label={label.sendToMailAddress} value={newEmail} />
                                </Box>

                                <Divider />

                                <Box sx={{ mt: 3, mb: 2 }} >
                                    {validErrors.authcodeError.map(obj =>
                                        <FormHelperText key={obj.id} sx={{ mb: 2 }} error={!Functions.isEmpty(validErrors.authcodeError)}>
                                            {ValidErrorMessages.editEmailCheckAuthcodeValidationError(obj)}
                                        </FormHelperText>
                                    )}
                                    <FormControl sx={{ width: '100%' }} variant="outlined" error={!Functions.isEmpty(validErrors.authcodeError)} required>
                                        <InputLabel htmlFor="outlined-login-email">{label.verificationCode}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-login-email"
                                            type="text"
                                            inputProps={{ inputmode: "numeric", pattern: "(0|[1-9][0-9]*)" }}
                                            label={label.verificationCode}
                                            value={authcode}
                                            onChange={onValueChange}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CommonButton onClick={onBack} sx={{ mr: 2, textTransform: 'none' }}>{commonButtonLabel.back}</CommonButton>
                                    <CommonButton onClick={onSendAuthcode} sx={{ textTransform: 'none' }}>{commonButtonLabel.confirm}</CommonButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </>
    );
}
