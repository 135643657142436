import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";
import ImageModel from "./ImageModel";

export default class ArtistModel {
    static DATA_TYPE_DETAIL = 'DATA_TYPE_DETAIL';
    static DATA_TYPE_FOLLOW = 'DATA_TYPE_FOLLOW';
    static DATA_TYPE_PICK_UP = 'DATA_TYPE_PICK_UP';

    constructor(dataObject = null, dataType) {
        this.Id = 0; // int
        this.JPArtistDisplayName = "";
        this.ENArtistDisplayName = "";
        this.JPArtistIntroduction = "";
        this.ENArtistIntroduction = "";
        this.JPArtistCompany = "";
        this.ENArtistCompany = "";
        this.ArtistImage = null; // ImageModel
        this.ArtistURL = []; // URLModel


        if (dataObject !== null) {
            switch (dataType) {
                case ArtistModel.DATA_TYPE_FOLLOW:
                case ArtistModel.DATA_TYPE_PICK_UP: {
                    this.Id = Number(dataObject.ArtistID);
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;

                    this.ArtistImage = new ImageModel();
                    this.ArtistImage.ImageFilepath = dataObject.ArtistImageFilepath;
                    this.ArtistImage.ImageObjectURL = dataObject.ArtistImageObjectURL;

                    break;
                }
                case ArtistModel.DATA_TYPE_DETAIL:
                default: {
                    this.Id = Number(dataObject.ArtistID);
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;
                    this.JPArtistIntroduction = dataObject.JPArtistIntroduction;
                    this.ENArtistIntroduction = dataObject.ENArtistIntroduction;
                    this.JPArtistCompany = dataObject.JPArtistCompany;
                    this.ENArtistCompany = dataObject.ENArtistCompany;
                    break;
                }
            }
        }
    }

    getArtistDisplayName(lang = JA) {
        if (lang === JA) {
            return this.JPArtistDisplayName;
        } else {
            return this.ENArtistDisplayName;
        }
    }

    getArtistIntroduction(lang = JA) {
        if (lang === JA) {
            return this.JPArtistIntroduction;
        } else {
            return this.ENArtistIntroduction;
        }
    }

    getArtistCompany(lang = JA) {
        if (lang === JA) {
            return this.JPArtistCompany;
        } else {
            return this.ENArtistCompany;
        }
    }

    update(dataObject) {
        const newArtistModel = new ArtistModel();

        newArtistModel.Id = this.Id;
        newArtistModel.JPArtistDisplayName = dataObject.JPArtistDisplayName;
        newArtistModel.ENArtistDisplayName = dataObject.ENArtistDisplayName;
        newArtistModel.JPArtistIntroduction = this.JPArtistIntroduction;
        newArtistModel.ENArtistIntroduction = this.ENArtistIntroduction;
        newArtistModel.JPArtistCompany = this.JPArtistCompany;
        newArtistModel.ENArtistCompany = this.ENArtistCompany;
        newArtistModel.ArtistImage = dataObject.ArtistImage;
        newArtistModel.ArtistURL = this.ArtistURL;

        return newArtistModel;
    }
}
