export default class ImageModel {
    constructor(dataObject = null) {
        this.ImageDisplayOrder = 0; // int
        this.ImageFilepath = "";
        this.ImageObjectURL = "";

        if (dataObject !== null) {
            this.ImageDisplayOrder = Number(dataObject.ImageDisplayOrder);
            this.ImageFilepath = dataObject.ImageFilepath;
            this.ImageObjectURL = dataObject.ImageObjectURL;
        }
    }
}
