import { Alert, AlertTitle, Box, Container, Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from "../CommonParts/Loader";
import { useAppContext } from "../../Contexts/AppContext";
import ImageScroller from '../CommonParts/ImageScroller';
import PurchasedMcaDetailedInformation from './Parts/PurchasedMcaDetailedInformation';
import PurchasedMcaRightsInformation from './Parts/PurchasedMcaRightsInformation';

export default function PurchasedMcaDetail() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const { id } = useParams();

    const [isTimeout, setIsTimeout] = useState(false);

    const artInfo = useMemo(() => {
        return Reducer.myArtInfoList.find(x => x.Id == id);
    }, [Reducer.myArtInfoList, id]);

    useEffect(() => {
        Actions.myArtInfoFetch({ MyArtID: Number(id) });
        Actions.userPrivilegeInfoFetch({ MyArtID: Number(id) });
        Actions.privilegePermissionHistoryFetch({ MyArtID: Number(id), Count: 10 })
        Actions.goodsListFetch({ MyArtID: Number(id), Count: 10 });
        return () => {
            Actions.myArtInfoFetching(false, false);
            Actions.userPrivilegeInfoFetching(false, false);
            Actions.privilegePermissionHistoryFetching(false, false);
            Actions.goodsListFetching(false, false);
            Actions.insertFollowContentsFetching(false, false);
            Actions.deleteFollowContentsFetching(false, false);
        }
    }, []);

    const loadingFlagKeys = ["myArtInfoFetch", "goodsListFetch", "userPrivilegeInfoFetch", "privilegePermissionHistoryFetch", "insertFollowContents", "deleteFollowContents"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (loadingFlag) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [loadingFlag]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={loadingFlag && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        My Art
                    </Typography>
                    {artInfo
                        ? <PurchasedMcaDetailedInformation
                            imgDataList={artInfo.ProductImage}
                            title={artInfo.getProductTitle(Reducer.language)}
                            artist={artInfo.getArtistDisplayName(Reducer.language)}
                            info={artInfo.gatProductDescription(Reducer.language)}
                            totalEditions={artInfo.TotalEditions}
                            editionNumber={artInfo.EditionNumber}
                            currencyUnit={artInfo.CurrencyUnit}
                            purchasePrice={artInfo.PurchasePrice}
                        />
                        : null
                    }
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Rights
                    </Typography>
                    <PurchasedMcaRightsInformation
                        privilegeInfo={Reducer.userPrivilegeInfo} privilegePermissionHistory={Reducer.privilegePermissionHistory}
                    />
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Goods
                    </Typography>
                    {Reducer.goodsList.length === 0
                        ? null
                        : <ImageScroller imgCardList={Reducer.goodsList} />
                    }
                </Box>
            </Container>
        </>
    );
}

