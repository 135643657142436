import dayjs from "dayjs";
import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";

export class Functions {
    // 空判定
    static isEmpty(data) {
        const dataType = Object.prototype.toString.call(data).replace('[object ', '').replace(']', '');
        // null, undefined
        if (dataType === "Null" || dataType === "Undefined") {
            return true;
        }
        // string
        if (dataType === "String" && data === "") {
            return true;
        }
        // Array
        if (dataType === "Array" && data.length === 0) {
            return true;
        }
        // Object
        if (dataType === "Object" && Object.keys(data).length === 0) {
            return true;
        }
        return false;
    };

    // 日付フォーマット
    static formatDateBySlash(date = null, lang = JA) {
        if (lang == JA) {
            if (date === null) {
                return dayjs().format("YYYY/MM/DD");
            } else {
                return dayjs(date).format("YYYY/MM/DD");
            }
        } else {
            if (date === null) {
                return dayjs().format("MM/DD/YYYY");
            } else {
                return dayjs(date).format("MM/DD/YYYY");
            }
        }
    };
}
