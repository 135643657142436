import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants';
import { useAppContext } from '../../../Contexts/AppContext';
import { ButtonForFavorite } from '../../CommonParts/CommonButton';
import { Functions } from '../../../Utils/Functions';

export default function DetailInfo({ artist, event, img, title, info, urlList, followStatus }) {
    const { Reducer, Actions } = useAppContext();
    const { id } = useParams();

    let titleLabel = "";
    if (artist) {
        titleLabel = "Artist";
    } else if (event) {
        titleLabel = "Event";
    };

    function onFollowClick() {
        let contentType = 0;
        if (artist) {
            contentType = Constants.FOLLOW_CONTENTS_TYPE_ARTIST;
        } else if (event) {
            contentType = Constants.FOLLOW_CONTENTS_TYPE_EVENT;
        }
        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            ContentsType: contentType,
            ContentsID: Number(id),
        };
        Actions.insertFollowContentsFetch(postData);
    }

    function onUnfollowClick() {
        let contentType = 0;
        if (artist) {
            contentType = Constants.FOLLOW_CONTENTS_TYPE_ARTIST;
        } else if (event) {
            contentType = Constants.FOLLOW_CONTENTS_TYPE_EVENT;
        }
        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            ContentsType: contentType,
            ContentsID: Number(id),
        };
        Actions.deleteFollowContentsFetch(postData);
    }

    function handleImageError(e) {
        e.target.src = Constants.NO_IMAGE_URL;
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item sm={6}>
                    <img src={img} alt={img} onError={handleImageError}
                        style={{ borderRadius: 4, width: '100%', aspectRatio: 1 / 1, objectFit: 'cover' }}
                    />
                </Grid>
                <Grid item container sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonForFavorite followStatus={followStatus} onFollowClick={onFollowClick} onUnfollowClick={onUnfollowClick} />
                    </div>
                    <div>
                        <Typography variant="subtitle2" sx={{ color: "#a9a9a9" }}>{titleLabel}</Typography>
                        <Typography variant="h5">{title}</Typography>
                        <Typography variant="subtitle2" className='detailInformationTitleStyle'>Information</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>{Functions.isEmpty(info) ? "" : info.replace(/。/g, '。\n')}</Typography>
                        <List>
                            {urlList?.map(urlInfo => (
                                <ListItem sx={{ padding: 0 }}>
                                    {urlInfo.URLTypeName}
                                    <span style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}>:</span>
                                    <Link href={urlInfo.URL} target="_blank" color="inherit">
                                        {urlInfo.URLDisplayText ? urlInfo.URLDisplayText : urlInfo.URL}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Grid>
            </Grid >
        </>
    );
}
