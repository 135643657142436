import * as ActionTypes from "../Constants/ActionTypes";

import { FOLLOW_CONTENTS_TYPE_ARTIST, FOLLOW_CONTENTS_TYPE_EVENT, FOLLOW_CONTENTS_TYPE_PRODUCT, LIST_TYPE_ART, LIST_TYPE_ARTIST, LIST_TYPE_EVENT, LIST_TYPE_GOODS, LIST_TYPE_MYART } from '../Constants/Constants';
import ArtistModel from "../Model/ArtistModel";
import ArtModel from "../Model/ArtModel";
import EventModel from "../Model/EventModel";
import GeneralCode from "../Model/GeneralCode";
import ImageCardModel from '../Model/ImageCardModel';
import ImageModel from "../Model/ImageModel";
import MyArtModel from "../Model/MyArtModel";
import NewsModel from "../Model/NewsModel";
import PrivilegePermissionHistoryModel from "../Model/PrivilegePermissionHistoryModel";
import URLModel from "../Model/URLModel";
import UserInfoModel from "../Model/UserInfoModel";
import UserPrivilegeModel from "../Model/UserPrivilegeModel";

const initialLoadingFlag = { loading: false, loadingError: false, statusCode: 0 };

export const initialState = {
    userAuth: {
        userId: "",
        userSub: "",
        email: ""
    },

    userInfo: new UserInfoModel(),

    language: window.navigator.language.substring(0, 2),

    searchResultListHistory: { currentPage: 1, conditions: [] },
    followContentsListHistory: { currentPage: 1, conditions: [] },
    myArtListHistory: { currentPage: 1, conditions: 2 },

    myArtList: [],
    newsList: [],
    followContentsList: [],
    pickUpContentsList: [],
    artistInfoList: [],
    relatedArtList: [],
    exhibitEventList: [],
    eventInfoList: [],
    exhibitArtList: [],
    exhibitArtistList: [],
    artInfoList: [],
    goodsList: [],
    myArtInfoList: [],
    userPrivilegeInfo: [],
    privilegePermissionHistory: [],
    searchResultList: [],
    countryCodeList: [],
    prefectureCodeList: [],

    // Loading flag
    userInfoFetch: initialLoadingFlag,
    myArtListFetch: initialLoadingFlag,
    followContentsListFetch: initialLoadingFlag,
    pickUpContentsListFetch: initialLoadingFlag,
    newsListFetch: initialLoadingFlag,
    artistInfoFetch: initialLoadingFlag,
    relatedArtListFetch: initialLoadingFlag,
    exhibitEventListFetch: initialLoadingFlag,
    eventInfoFetch: initialLoadingFlag,
    exhibitArtListFetch: initialLoadingFlag,
    exhibitArtistListFetch: initialLoadingFlag,
    artInfoFetch: initialLoadingFlag,
    goodsListFetch: initialLoadingFlag,
    myArtInfoFetch: initialLoadingFlag,
    userPrivilegeInfoFetch: initialLoadingFlag,
    privilegePermissionHistoryFetch: initialLoadingFlag,
    searchResultListFetch: initialLoadingFlag,
    insertFollowContents: initialLoadingFlag,
    deleteFollowContents: initialLoadingFlag,
    countoryCodeListFetch: initialLoadingFlag,
    prefectureCodeListFetch: initialLoadingFlag,
};

export default function Reducer(state, action) {
    switch (action.type) {
        case ActionTypes.SET_USERAUTH: {
            return {
                ...state,
                userAuth: {
                    ...state.userAuth,
                    userId: action.userId,
                    userSub: action.userSub,
                    email: action.email
                }
            };
        }

        case ActionTypes.SET_USERAUTH_USERSUB: {
            return {
                ...state,
                userAuth: {
                    ...state.userAuth,
                    userSub: action.userSub,
                    email: action.email
                }
            };
        }

        case ActionTypes.SET_USERAUTH_USERID: {
            return {
                ...state,
                userAuth: {
                    ...state.userAuth,
                    userId: action.userId
                }
            };
        }

        case ActionTypes.USERINFO_ACT_FETCHING: {
            return {
                ...state,
                userInfoFetch: {
                    ...state.userInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_USERINFO: {
            try {
                const userInfo = new UserInfoModel(action.userInfo);

                return {
                    ...state,
                    userInfo: userInfo
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.SET_LANGUAGE: {
            return {
                ...state,
                language: action.language
            };
        }

        case ActionTypes.MYARTLIST_ACT_FETCHING: {
            return {
                ...state,
                myArtListFetch: {
                    ...state.myArtListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_MYARTLIST: {
            try {
                const myArtListData = action.myArtList
                    .map(item => new ImageCardModel(item, LIST_TYPE_MYART))
                    .sort((a, b) => {
                        const typeADate = a.AddedDateTime;
                        const typeBDate = b.AddedDateTime;

                        if (typeADate == typeBDate) {
                            return a.Id - b.Id
                        }
                        return typeADate > typeBDate ? -1 : 1;

                    });

                const formattedMyArtInfoList = action.myArtList.map(item => {
                    const formattedMyArtItem = new MyArtModel(item, MyArtModel.DATA_TYPE_MY_ART);
                    const foundMyArtInfo = state.myArtInfoList.find(x => x.Id == formattedMyArtItem.Id);
                    if (foundMyArtInfo) {
                        return foundMyArtInfo.update(formattedMyArtItem);
                    } else {
                        return formattedMyArtItem;
                    }
                });
                const nonUpdatedMyArtInfoList = state.myArtInfoList.filter(item => formattedMyArtInfoList.every(x => x.Id !== item.Id));

                return {
                    ...state,
                    myArtList: myArtListData,
                    myArtInfoList: [...nonUpdatedMyArtInfoList, ...formattedMyArtInfoList],
                    // artInfoList: [...nonUpdatedArtInfoList, ...formattedArtInfoList],
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.FOLLOWCONTENTSLIST_ACT_FETCHING: {
            return {
                ...state,
                followContentsListFetch: {
                    ...state.followContentsListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_FOLLOWCONTENTSLIST: {
            try {
                let followContentsListData = [];
                let formattedArtInfoList = [];
                let formattedArtistInfoList = [];
                let formattedEventInfoList = [];
                if (action.followContentsList.FollowArtList) {
                    const followArtList = action.followContentsList.FollowArtList.map(item => new ImageCardModel(item, LIST_TYPE_ART));
                    followContentsListData = followContentsListData.concat(followArtList);

                    formattedArtInfoList = action.followContentsList.FollowArtList.map(item => {
                        const formattedItem = new ArtModel(item, ArtModel.DATA_TYPE_FOLLOW);
                        const foundArtInfo = state.artInfoList.find(x => x.Id == formattedItem.Id);
                        if (foundArtInfo) {
                            return foundArtInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });
                }
                if (action.followContentsList.FollowArtistList) {
                    const followArtistList = action.followContentsList.FollowArtistList.map(item => new ImageCardModel(item, LIST_TYPE_ARTIST));
                    followContentsListData = followContentsListData.concat(followArtistList);

                    formattedArtistInfoList = action.followContentsList.FollowArtistList.map(item => {
                        const formattedItem = new ArtistModel(item, ArtistModel.DATA_TYPE_FOLLOW);
                        const foundArtistInfo = state.artistInfoList.find(x => x.Id == formattedItem.Id);
                        if (foundArtistInfo) {
                            return foundArtistInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });

                }
                if (action.followContentsList.FollowEventList) {
                    const followEventList = action.followContentsList.FollowEventList.map(item => new ImageCardModel(item, LIST_TYPE_EVENT));
                    followContentsListData = followContentsListData.concat(followEventList);

                    formattedEventInfoList = action.followContentsList.FollowEventList.map(item => {
                        const formattedItem = new EventModel(item, EventModel.DATA_TYPE_FOLLOW);
                        const foundEventInfo = state.eventInfoList.find(x => x.EventID == formattedItem.EventID);
                        if (foundEventInfo) {
                            return foundEventInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });
                }

                followContentsListData.sort((a, b) => {
                    if (a.AddedDateTime == b.AddedDateTime) {
                        return 0;
                    } else if (a.AddedDateTime > b.AddedDateTime) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                const nonUpdatedArtInfoList = state.artInfoList.filter(item => formattedArtInfoList.every(x => x.Id !== item.Id));
                const nonUpdateArtistInfoList = state.artistInfoList.filter(item => formattedArtistInfoList.every(x => x.Id !== item.Id));
                const nonUpdateEventInfoList = state.eventInfoList.filter(item => formattedEventInfoList.every(x => x.Id !== item.Id));

                return {
                    ...state,
                    followContentsList: followContentsListData,
                    artInfoList: [...nonUpdatedArtInfoList, ...formattedArtInfoList],
                    artistInfoList: [...nonUpdateArtistInfoList, ...formattedArtistInfoList],
                    eventInfoList: [...nonUpdateEventInfoList, ...formattedEventInfoList],
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.ADD_FOLLOWCONTENTSLIST: {
            // TODO: 現状の実装状況に合わせているので、APIが修正されたら直すこと
            const followContent = new ImageCardModel();
            followContent.Id = action.postData.ContentsID;
            followContent.IsArtist = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_ARTIST;
            followContent.IsEvent = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_EVENT;
            followContent.IsArt = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_PRODUCT;

            return {
                ...state,
                followContentsList: [...state.followContentsList, followContent],
            };
        }

        case ActionTypes.DELETE_FOLLOWCONTENTSLIST: {
            const IsArtist = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_ARTIST;
            const IsEvent = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_EVENT;
            const IsArt = action.postData.ContentsType === FOLLOW_CONTENTS_TYPE_PRODUCT;
            return {
                ...state,
                followContentsList: state.followContentsList.filter(x => (
                    x.Id != action.postData.ContentsID
                    && x.IsArtist == IsArtist && x.IsEvent == IsEvent && x.IsArt == IsArt
                )),
            };
        }

        case ActionTypes.PICKUPCONTENTSLIST_ACT_FETCHING: {
            return {
                ...state,
                pickUpContentsListFetch: {
                    ...state.pickUpContentsListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_PICKUPCONTENTSLIST: {
            try {
                let pickUpContentsListData = [];
                let formattedArtInfoList = [];
                let formattedArtistInfoList = [];
                let formattedEventInfoList = [];
                if (action.pickUpContentsList.PickUpArtList) {
                    const pickUpArtList = action.pickUpContentsList.PickUpArtList?.map(item => new ImageCardModel(item, LIST_TYPE_ART));
                    pickUpContentsListData = pickUpContentsListData.concat(pickUpArtList);

                    formattedArtInfoList = action.pickUpContentsList.PickUpArtList.map(item => {
                        const formattedItem = new ArtModel(item, ArtModel.DATA_TYPE_PICK_UP);
                        const foundArtInfo = state.artInfoList.find(x => x.Id == formattedItem.Id);
                        if (foundArtInfo) {
                            return foundArtInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });
                }
                if (action.pickUpContentsList.PickUpArtistList) {
                    const pickUpArtistList = action.pickUpContentsList.PickUpArtistList?.map(item => new ImageCardModel(item, LIST_TYPE_ARTIST));
                    pickUpContentsListData = pickUpContentsListData.concat(pickUpArtistList);

                    formattedArtistInfoList = action.pickUpContentsList.PickUpArtistList.map(item => {
                        const formattedItem = new ArtistModel(item, ArtistModel.DATA_TYPE_PICK_UP);
                        const foundArtistInfo = state.artistInfoList.find(x => x.Id == formattedItem.Id);
                        if (foundArtistInfo) {
                            return foundArtistInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });
                }
                if (action.pickUpContentsList.PickUpEventList) {
                    const pickUpEventList = action.pickUpContentsList.PickUpEventList?.map(item => new ImageCardModel(item, LIST_TYPE_EVENT));
                    pickUpContentsListData = pickUpContentsListData.concat(pickUpEventList);

                    formattedEventInfoList = action.pickUpContentsList.PickUpEventList.map(item => {
                        const formattedItem = new EventModel(item, EventModel.DATA_TYPE_PICK_UP);
                        const foundEventInfo = state.eventInfoList.find(x => x.EventID == formattedItem.EventID);
                        if (foundEventInfo) {
                            return foundEventInfo.update(formattedItem);
                        } else {
                            return formattedItem;
                        }
                    });
                }

                pickUpContentsListData.sort((a, b) => {
                    if (a.AddedDateTime == b.AddedDateTime) {
                        return 0;
                    } else if (a.AddedDateTime > b.AddedDateTime) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                const nonUpdatedArtInfoList = state.artInfoList.filter(item => formattedArtInfoList.every(x => x.Id !== item.Id));
                const nonUpdateArtistInfoList = state.artistInfoList.filter(item => formattedArtistInfoList.every(x => x.Id !== item.Id));
                const nonUpdateEventInfoList = state.eventInfoList.filter(item => formattedEventInfoList.every(x => x.Id !== item.Id));

                return {
                    ...state,
                    pickUpContentsList: pickUpContentsListData,
                    artInfoList: [...nonUpdatedArtInfoList, ...formattedArtInfoList],
                    artistInfoList: [...nonUpdateArtistInfoList, ...formattedArtistInfoList],
                    eventInfoList: [...nonUpdateEventInfoList, ...formattedEventInfoList],
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.NEWSLIST_ACT_FETCHING: {
            return {
                ...state,
                newsListFetch: {
                    ...state.newsListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_NEWSLIST: {
            try {
                const newsListData = action.newsList.map(news => {
                    let newNews = new NewsModel(news);
                    newNews.NewsURL = news.NewsURL.map(x => new URLModel(x))
                    return newNews;
                });

                return {
                    ...state,
                    newsList: newsListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.ARTISTINFO_ACT_FETCHING: {
            return {
                ...state,
                artistInfoFetch: {
                    ...state.artistInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_ARTISTINFO: {
            try {
                let artistInfo = new ArtistModel(action.artistInfo, ArtistModel.DATA_TYPE_DETAIL);
                artistInfo.ArtistImage = new ImageModel(action.artistInfo.ArtistImage[0]);
                artistInfo.ArtistURL = action.artistInfo.ArtistURL.map(item => new URLModel(item));

                const newArtistInfoList = state.artistInfoList.filter(x => x.Id !== artistInfo.Id);
                newArtistInfoList.push(artistInfo);

                return {
                    ...state,
                    artistInfoList: newArtistInfoList,
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.RELATEDARTLIST_ACT_FETCHING: {
            return {
                ...state,
                relatedArtListFetch: {
                    ...state.relatedArtListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_RELATEDARTLIST: {
            try {
                const relatedArtListData = action.relatedArtList.map(item => new ImageCardModel(item, LIST_TYPE_ART));

                return {
                    ...state,
                    relatedArtList: relatedArtListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.EXHIBITEVENTLIST_ACT_FETCHING: {
            return {
                ...state,
                exhibitEventListFetch: {
                    ...state.exhibitEventListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_EXHIBITEVENTLIST: {
            try {
                const exhibitEventListData = action.exhibitEventList.map(item => new ImageCardModel(item, LIST_TYPE_EVENT));

                return {
                    ...state,
                    exhibitEventList: exhibitEventListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.EVENTINFO_ACT_FETCHING: {
            return {
                ...state,
                eventInfoFetch: {
                    ...state.eventInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_EVENTINFO: {
            try {
                let eventInfo = new EventModel(action.eventInfo, EventModel.DATA_TYPE_DETAIL);
                eventInfo.EventImage = new ImageModel(action.eventInfo.EventImage[0]);
                eventInfo.EventURL = action.eventInfo.EventURL.map(x => new URLModel(x));

                const newEventInfoList = state.eventInfoList.filter(x => x.EventID !== eventInfo.EventID);
                newEventInfoList.push(eventInfo);

                return {
                    ...state,
                    eventInfoList: newEventInfoList,
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.EXHIBITARTLIST_ACT_FETCHING: {
            return {
                ...state,
                exhibitArtListFetch: {
                    ...state.exhibitArtListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_EXHIBITARTLIST: {
            try {
                const exhibitArtListData = action.exhibitArtList.map(item => new ImageCardModel(item, LIST_TYPE_ART));
                return {
                    ...state,
                    exhibitArtList: exhibitArtListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.EXHIBITARTISTLIST_ACT_FETCHING: {
            return {
                ...state,
                exhibitArtistListFetch: {
                    ...state.exhibitArtistListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_EXHIBITARTISTLIST: {
            try {
                const exhibitArtistListData = action.exhibitArtistList.map(item => new ImageCardModel(item, LIST_TYPE_ARTIST));
                return {
                    ...state,
                    exhibitArtistList: exhibitArtistListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.ARTINFO_ACT_FETCHING: {
            return {
                ...state,
                artInfoFetch: {
                    ...state.artInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_ARTINFO: {
            try {
                let artInfo = new ArtModel(action.artInfo, ArtModel.DATA_TYPE_DETAIL);
                artInfo.ProductImage = action.artInfo.ProductImage.map(x => new ImageModel(x));
                artInfo.ProductURL = action.artInfo.ProductURL.map(x => new URLModel(x));

                const newArtInfoList = state.artInfoList.filter(x => x.Id !== artInfo.Id);
                newArtInfoList.push(artInfo);

                return {
                    ...state,
                    artInfoList: newArtInfoList,
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.GOODSLIST_ACT_FETCHING: {
            return {
                ...state,
                goodsListFetch: {
                    ...state.goodsListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_GOODSLIST: {
            try {
                const goodsListData = action.goodsList.map(item => {
                    let goods = new ImageCardModel(item, LIST_TYPE_GOODS);
                    goods.GoodsURL = new URLModel(item.GoodsURL[0]);
                    return goods;
                })

                return {
                    ...state,
                    goodsList: goodsListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.MYARTINFO_ACT_FETCHING: {
            return {
                ...state,
                myArtInfoFetch: {
                    ...state.artInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_MYARTINFO: {
            try {
                let myArtInfo = new MyArtModel(action.myArtInfo, MyArtModel.DATA_TYPE_DETAIL);
                myArtInfo.ProductImage = action.myArtInfo.ProductImage.map(x => new ImageModel(x));
                myArtInfo.ProductURL = action.myArtInfo.ProductURL.map(x => new URLModel(x));

                const newMyArtInfoList = state.myArtInfoList.filter(x => x.Id !== myArtInfo.Id);
                newMyArtInfoList.push(myArtInfo);

                return {
                    ...state,
                    myArtInfoList: newMyArtInfoList,
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.USERPRIVILEGEINFO_ACT_FETCHING: {
            return {
                ...state,
                userPrivilegeInfoFetch: {
                    ...state.userPrivilegeInfoFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_USERPRIVILEGEINFO: {
            try {
                const userPrivilegeInfo = action.userPrivilegeInfo.map(x => new UserPrivilegeModel(x));

                return {
                    ...state,
                    userPrivilegeInfo: userPrivilegeInfo
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.PRIVILEGEPERMISSIOMHISTORY_ACT_FETCHING: {
            return {
                ...state,
                privilegePermissionHistoryFetch: {
                    ...state.privilegePermissionHistoryFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_PRIVILEGEPERMISSIOMHISTORY: {
            try {
                const privilegePermissionHistory = action.privilegePermissionHistory.map(x => new PrivilegePermissionHistoryModel(x));

                return {
                    ...state,
                    privilegePermissionHistory: privilegePermissionHistory
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.SEARCHRESULTLIST_ACT_FETCHING: {
            return {
                ...state,
                searchResultListFetch: {
                    ...state.searchResultListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_SEARCHRESULTLIST: {
            try {
                let searchResultListData = [];
                if (action.searchResultList.ArtList) {
                    const artList = action.searchResultList.ArtList?.map(item => new ImageCardModel(item, LIST_TYPE_ART));
                    searchResultListData = searchResultListData.concat(artList);
                }
                if (action.searchResultList.ArtistList) {
                    const artistList = action.searchResultList.ArtistList?.map(item => new ImageCardModel(item, LIST_TYPE_ARTIST));
                    searchResultListData = searchResultListData.concat(artistList);
                }
                if (action.searchResultList.EventList) {
                    const eventList = action.searchResultList.EventList?.map(item => new ImageCardModel(item, LIST_TYPE_EVENT));
                    searchResultListData = searchResultListData.concat(eventList);
                }

                return {
                    ...state,
                    searchResultList: searchResultListData
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.INSERTFOLLOWCONTENTS_ACT_FETCHING: {
            try {
                return {
                    ...state,
                    insertFollowContents: {
                        ...state.insertFollowContents,
                        loading: action.loading,
                        loadingError: action.loadingError,
                        statusCode: action.statusCode,
                    },
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.DELETEFOLLOWCONTENTS_ACT_FETCHING: {
            try {
                return {
                    ...state,
                    deleteFollowContents: {
                        ...state.deleteFollowContents,
                        loading: action.loading,
                        loadingError: action.loadingError,
                        statusCode: action.statusCode,
                    },
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.COUNTRYCODELIST_ACT_FETCHING: {
            return {
                ...state,
                countoryCodeListFetch: {
                    ...state.countoryCodeListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_COUNTRY_CODE_LIST: {
            try {
                return {
                    ...state,
                    countryCodeList: action.generalCodeList.map(x => new GeneralCode(x)),
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.PREFECTURECODELIST_ACT_FETCHING: {
            return {
                ...state,
                prefectureCodeListFetch: {
                    ...state.prefectureCodeListFetch,
                    loading: action.loading,
                    loadingError: action.loadingError,
                    statusCode: action.statusCode
                }
            };
        }

        case ActionTypes.SET_PREFECTURE_CODE_LIST: {
            try {
                return {
                    ...state,
                    prefectureCodeList: action.prefectureCodeList.map(x => new GeneralCode(x)),
                };
            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.SET_SEARCHRESULTLIST_HISTORY: {
            try {
                if (action.conditions === null) {
                    return {
                        ...state,
                        searchResultListHistory: {
                            ...state.searchResultListHistory,
                            currentPage: action.currentPage,
                        },
                    };
                } else {
                    return {
                        ...state,
                        searchResultListHistory: {
                            ...state.searchResultListHistory,
                            currentPage: action.currentPage,
                            conditions: action.conditions
                        },
                    };
                }

            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.SET_FOLLOWCONTENTSLIST_HISTORY: {
            try {
                if (action.conditions === null) {
                    return {
                        ...state,
                        followContentsListHistory: {
                            ...state.followContentsListHistory,
                            currentPage: action.currentPage,
                        },
                    };
                } else {
                    return {
                        ...state,
                        followContentsListHistory: {
                            ...state.followContentsListHistory,
                            currentPage: action.currentPage,
                            conditions: action.conditions
                        },
                    };
                }

            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        case ActionTypes.SET_MYARTLIST_HISTORY: {
            try {
                if (action.conditions === null) {
                    return {
                        ...state,
                        myArtListHistory: {
                            ...state.myArtListHistory,
                            currentPage: action.currentPage,
                        },
                    };
                } else {
                    return {
                        ...state,
                        myArtListHistory: {
                            ...state.myArtListHistory,
                            currentPage: action.currentPage,
                            conditions: action.conditions
                        },
                    };
                }

            } catch (e) {
                throw "Data format is not valid. " + e.message;
            }
        }

        default:
            return state;
    }
}
