import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useAppContext } from "../../Contexts/AppContext";
import { ButtonToOtherPage } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';

export default function SingupComfirm() {
    const { LanguageResponsiveMessages } = useAppContext();

    const accountData = {
        mail: "mail_address@xmail.com", password: "********", born: "20xx年 xx月 xx日",
        area: "日本", name: "日本 太郎", phone: "00-1234-5678", zipCode: "000-0000", prefecture: "東京都",
        city: "○○市××町", streetAddress: "1-2-3", building: "□□マンション１０１",
    }

    const label = LanguageResponsiveMessages.userInformationLabel;
    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <Container maxWidth="lg">
            <Box component="div" className='titleStyle'>
                <Typography variant="h3" mb={2}>
                    Sign Up
                </Typography>
                <Paper>
                    <Grid container justifyContent="center" direction='column' sx={{ padding: 5 }}>
                        <LabelValuePair label={loginInfoLabel.loginId} value={accountData.mail} />
                        <LabelValuePair label={loginInfoLabel.password} value={accountData.password} />
                        <LabelValuePair label={label.birthDate} value={accountData.born} />
                        <LabelValuePair label={label.country} value={accountData.area} />
                        <LabelValuePair label={label.fullName} value={accountData.name} />
                        <LabelValuePair label={label.phoneNumber} value={accountData.phone} />
                        <LabelValuePair label={label.zipCode} value={accountData.zipCode} />
                        <LabelValuePair label={label.prefecture} value={accountData.prefecture} />
                        <LabelValuePair label={label.city} value={accountData.city} />
                        <LabelValuePair label={label.cityBlock} value={accountData.streetAddress} />
                        <LabelValuePair label={label.buildingName} value={accountData.building} />
                        <Grid container justifyContent="center" sx={{ paddingTop: 3 }}>
                            <ButtonToOtherPage label={commonButtonLabel.back} to="/singup/input" margin="0 10px" />
                            <ButtonToOtherPage label={commonButtonLabel.save} margin="0 10px" />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}
