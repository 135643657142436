import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";

export default class PrivilegePermissionHistoryModel {
    constructor(dataObject = null) {
        this.JPPermitterType = "";
        this.ENPermitterType = "";
        this.JPContentsName = "";
        this.ENContentsName = "";
        this.JPPrivilegeDetail = "";
        this.ENPrivilegeDetail = "";
        this.JPPermissionStatus = "";
        this.ENPermissionStatus = "";
        this.PermissionReason = "";
        this.PermissionDateTime = "";


        if (dataObject !== null) {
            this.JPPermitterType = dataObject.JPPermitterType;
            this.ENPermitterType = dataObject.ENPermitterType;
            this.JPContentsName = dataObject.JPContentsName;
            this.ENContentsName = dataObject.ENContentsName;
            this.JPPrivilegeDetail = dataObject.JPPrivilegeDetail;
            this.ENPrivilegeDetail = dataObject.ENPrivilegeDetail;
            this.JPPermissionStatus = dataObject.JPPermissionStatus;
            this.ENPermissionStatus = dataObject.ENPermissionStatus;
            this.PermissionReason = dataObject.PermissionReason;
            this.PermissionDateTime = dataObject.PermissionDateTime;
        }
    }

    getPermitterType(lang = JA) {
        if (lang === JA) {
            return this.JPPermitterType;
        } else {
            return this.ENPermitterType;
        }
    }

    getContentsName(lang = JA) {
        if (lang === JA) {
            return this.JPContentsName;
        } else {
            return this.ENContentsName;
        }
    }

    getPrivilegeDetail(lang = JA) {
        if (lang === JA) {
            return this.JPPrivilegeDetail;
        } else {
            return this.ENPrivilegeDetail;
        }
    }

    getPermissionStatus(lang = JA) {
        if (lang === JA) {
            return this.JPPermissionStatus;
        } else {
            return this.ENPermissionStatus;
        }
    }
}
