import { Alert, AlertTitle, Box, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect } from 'react';
import { useAppContext } from '../../Contexts/AppContext';
import { ButtonToOtherPage } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';
import Loader from "../CommonParts/Loader";

export default function SingupInput() {
    const { Reducer, Actions, LanguageResponsiveMessages, ApiErrorMessages } = useAppContext();

    useEffect(() => {
        if (Reducer.countryCodeList.length === 0) {
            Actions.countoryCodeListFetch();
        }
        if (Reducer.prefectureCodeList.length === 0) {
            Actions.prefectureCodeListFetch();
        }
    }, []);

    const accountData = {
        mail: "mail_address@xmail.com", password: "********"
    };

    const areaOptions = Reducer.countryCodeList.sort((a, b) => a.SortOrder - b.SortOrder)
        .map(x => ({ id: x.Code, label: x.JPCodeDescription, }));

    const prefectureOptions = Reducer.prefectureCodeList.sort((a, b) => a.SortOrder - b.SortOrder)
        .map(x => ({ id: x.Code, label: x.JPCodeDescription, }));

    const loadingFlagKeys = ["countoryCodeListFetch", "prefectureCodeListFetch"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
            </Alert>
            : null;

    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const label = LanguageResponsiveMessages.userInformationLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <Container maxWidth='lg'>
            <Loader open={loadingFlag} />
            {errorMessage}
            <Box component='div' className='titleStyle'>
                <Typography variant='h3' mb={2}>
                    Sign Up
                </Typography>
                <Paper>
                    <Grid container justifyContent='center' direction='column' sx={{ padding: 4 }}>

                        <LabelValuePair label={loginInfoLabel.loginId} value={accountData.mail} />
                        <LabelValuePair label={loginInfoLabel.password} value={accountData.password} />

                        <Divider />

                        <Typography variant="body2" sx={{ mr: 2, ml: 2, mt: 2 }} color='text.secondary'>
                            {LanguageResponsiveMessages.birthDateCannotBeChanged}
                        </Typography>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <DatePicker label={label.birthDate} openTo="year" />
                        </FormControl>

                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel>{label.country}</InputLabel>
                            <Select input={<OutlinedInput label={label.country} />}>
                                {areaOptions.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Grid item sx={{ display: 'flex', flexWrap: 'nowrap', m: 1 }} spacing={1}>
                            <TextField item label={label.lastName} variant="outlined" fullWidth sx={{ mr: 1 }} />
                            <TextField item label={label.firstName} variant="outlined" fullWidth sx={{ ml: 1 }} />
                        </Grid>

                        <TextField label={label.phoneNumber} variant="outlined" sx={{ m: 1 }} fullWidth />

                        <Grid item sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
                            <TextField label={label.zipCode} variant="outlined" fullWidth />
                            <Typography variant="body1" sx={{ mr: 2, ml: 2 }}>-</Typography>
                            <TextField variant="outlined" fullWidth />
                        </Grid>

                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel>{label.prefecture}</InputLabel>
                            <Select input={<OutlinedInput label={label.prefecture} />}>
                                {prefectureOptions.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField label={label.city} variant="outlined" sx={{ m: 1 }} fullWidth />
                        <TextField label={label.cityBlock} variant="outlined" sx={{ m: 1 }} fullWidth />
                        <TextField label={label.buildingName} variant="outlined" sx={{ m: 1 }} fullWidth />

                        <FormGroup sx={{ m: 1 }}>
                            <FormControlLabel control={<Checkbox color="default" />}
                                label={LanguageResponsiveMessages.agreeConditionsOfUse} />
                            <FormControlLabel control={<Checkbox color="default" />} label={LanguageResponsiveMessages.notRobot} />
                        </FormGroup>

                        <Grid item container sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <ButtonToOtherPage label={commonButtonLabel.confirm} to="/singup/comfirm" margin="0 10px" />
                            <ButtonToOtherPage label={commonButtonLabel.skipInput} to="/singup/comfirm" margin="0 10px" />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

        </Container>
    );
}
