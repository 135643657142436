import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Container, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, OutlinedInput, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonButton } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';
import { useAppContext } from '../../Contexts/AppContext';
import AppConfig from "../../Utils/AppConfig";
import { Functions } from "../../Utils/Functions";
import Loader from '../CommonParts/Loader';

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlags = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

// バリデーションエラー(エラーを表示する入力欄分のリストを作っておく)
const initialValidError = {
    currentPassError: [],
    newPassError: [],
    reEnteringPassError: [],
};

export default function EditPassword() {
    const { Reducer, LanguageResponsiveMessages, ValidErrorMessages, ApiErrorMessages } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentEmail } = location.state;
    const [loadingFlags, setLoadingFlags] = useState(initialLoadingFlags);
    const [formValue, setFormValue] = useState({
        currentPass: "",
        newPass: "",
        retypePass: ""
    });
    const [showPassFlg, setShowPassFlg] = useState({
        isShowCurrentPass: false,
        isShowNewPass: false,
        isShowRetypePass: false
    });
    const [validErrors, setValidErrors] = useState(initialValidError);

    function onFormValueChange(event) {
        const { name, value } = event.target;
        const newFormValue = Object.assign({}, formValue, { [name]: value })
        setFormValue(newFormValue);
    }

    function onShowPassClick(name) {
        const newShowPassFlg = Object.assign({}, showPassFlg, { [name]: !showPassFlg[name] });
        setShowPassFlg(newShowPassFlg);
    };

    function onMouseDownPass(event) {
        event.preventDefault();
    };

    function onInputKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function validCheck() {
        let errors = {
            currentPassError: [],
            newPassError: [],
            reEnteringPassError: [],
        }

        if (Functions.isEmpty(formValue.currentPass)) {
            errors.currentPassError.push({
                id: 0,
                type: "requiredValue",
                jaWords: ["現在のパスワード"],
                enWords: ["current password"],
            });
        }

        if (Functions.isEmpty(formValue.newPass)) {
            errors.newPassError.push({
                id: 0,
                type: "requiredValue",
                jaWords: ["新パスワード"],
                enWords: ["new password"],
            });
        } else {
            if (!/[a-z]/g.test(formValue.newPass)) {
                errors.newPassError.push({
                    id: 1,
                    type: "notEntered",
                    jaWords: ["小文字", "パスワード"],
                    enWords: ["lower case letters", "password"],
                });
            }

            if (!/[A-Z]/g.test(formValue.newPass)) {
                errors.newPassError.push({
                    id: 2,
                    type: "notEntered",
                    jaWords: ["大文字", "パスワード"],
                    enWords: ["upper case letters", "password"],
                });
            }

            if (!/[0-9]/g.test(formValue.newPass)) {
                errors.newPassError.push({
                    id: 3,
                    type: "notEntered",
                    jaWords: ["数字", "パスワード"],
                    enWords: ["number", "password"],
                });
            }

            if (formValue.newPass.length < 8) {
                errors.newPassError.push({
                    id: 4,
                    type: "minimumCharacters",
                    jaWords: [8, "パスワード"],
                    enWords: [8, "password"],
                });
            }

            if (!(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g.test(formValue.newPass)) && !formValue.newPass.includes(" ")) {
                errors.newPassError.push({
                    id: 5,
                    type: "notEntered",
                    jaWords: ["特殊文字またはスペース", "パスワード"],
                    enWords: ["special characters or spaces", "password"],
                });
            } else {
                if (formValue.newPass.endsWith(" ") || formValue.newPass.startsWith(" ")) {
                    errors.newPassError.push({
                        id: 6,
                        type: "spaceError",
                        jaWords: ["パスワード"],
                        enWords: ["password"],
                    });
                }
            }
        }

        if (Functions.isEmpty(formValue.retypePass)) {
            errors.reEnteringPassError.push({
                id: 0,
                type: "requiredValue",
                jaWords: ["再入力した新パスワード"],
                enWords: ["new password (re-entering)"],
            });
        } else {
            if (formValue.newPass !== formValue.retypePass) {
                errors.reEnteringPassError.push({
                    id: 1,
                    type: "differentInputValues",
                    jaWords: ["再入力した新パスワード"],
                    enWords: ["The new password you re-entered"],
                });
            }
        }

        setValidErrors(errors);
        if (errors.currentPassError.length || errors.newPassError.length || errors.reEnteringPassError.length) {
            return false;
        }
        return true;
    }

    function onSendPassword() {
        if (!validCheck()) {
            return;
        }

        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            old_password: formValue.currentPass,
            new_password: formValue.newPass,
        };

        setLoadingFlags({ loading: true, loadingError: false, statusCode: 0 });
        fetch(AppConfig.UpdatePassword, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(err => {
                            throw err
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(json => {
                if (json.statusCode == 200) {
                    navigate("/account/userInfo/edit/complete", { state: { message: loginInfoLabel.password } });
                } else {
                    setLoadingFlags({ loading: false, loadingError: true, statusCode: json.statusCode });
                }
            })
            .catch(e => {
                setLoadingFlags({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
            });
    }

    const errorMessage =
        loadingFlags.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {ApiErrorMessages.apiMessage(loadingFlags.statusCode, "EditPassword.onSendPassword")}
            </Alert>
            : null;

    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <>
            <Container maxWidth="lg" >
                <Loader open={loadingFlags.loading} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Reset Password
                    </Typography>
                    <Paper sx={{ py: 6, px: 2 }} >
                        <Box component="form" sx={{ mx: { xs: 0, sm: 4 } }} >
                            <Grid container spacing={1} direction="column" justifyContent="center">
                                <Box sx={{ mb: 1 }}>
                                    <LabelValuePair label={loginInfoLabel.loginId} value={currentEmail} />
                                </Box>

                                <Divider />

                                <div>
                                    <Accordion disableGutters>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography variant='body2'>{LanguageResponsiveMessages.inputNotesTitle}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List sx={{ py: 0 }}>
                                                {Object.keys(LanguageResponsiveMessages.notesOnPasswordEntry).map(k =>
                                                    <ListItem>
                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                variant: 'subtitle2',
                                                                lineHeight: '5px',
                                                                color: "text.secondary",
                                                            }}
                                                            primary={LanguageResponsiveMessages.notesOnPasswordEntry[k]}
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <Grid xs={12} sx={{ width: "100%" }}>
                                    <Box component="div" sx={{ mb: 2 }} >
                                        {validErrors.currentPassError.map(obj =>
                                            <FormHelperText key={obj.id} sx={{ mb: 1 }}
                                                error={!Functions.isEmpty(validErrors.currentPassError)}
                                            >
                                                {ValidErrorMessages.passwordValidationError(obj)}
                                            </FormHelperText>
                                        )}
                                        <FormControl sx={{ width: '100%' }} variant="outlined" required
                                            error={!Functions.isEmpty(validErrors.currentPassError)}
                                        >
                                            <InputLabel htmlFor="outlined-login-currentPass">{loginInfoLabel.currentPassword}</InputLabel>
                                            <OutlinedInput
                                                id="outlined-login-currentPass"
                                                onChange={onFormValueChange}
                                                type={showPassFlg.isShowCurrentPass ? 'text' : 'password'}
                                                value={formValue.currentPass}
                                                name="currentPass"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => onShowPassClick("isShowCurrentPass")}
                                                            onMouseDown={onMouseDownPass}
                                                            edge="end"
                                                        >
                                                            {showPassFlg.isShowCurrentPass ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                autoComplete="current-password"
                                                label={loginInfoLabel.currentPassword}
                                                onKeyDown={onInputKeyDown}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                {/* new_password_text_field */}
                                <Grid xs={12} sx={{ width: "100%" }}>
                                    <Box component="div" sx={{ mb: 2 }}>
                                        {validErrors.newPassError.map(obj =>
                                            <FormHelperText key={obj.id} sx={{ mb: 1 }}
                                                error={!Functions.isEmpty(validErrors.newPassError)}
                                            >
                                                {ValidErrorMessages.passwordValidationError(obj)}
                                            </FormHelperText>
                                        )}
                                        <FormControl sx={{ width: '100%' }} variant="outlined" required
                                            error={!Functions.isEmpty(validErrors.newPassError)}
                                        >
                                            <InputLabel htmlFor="outlined-login-showNewPass">{loginInfoLabel.newPassword}</InputLabel>
                                            <OutlinedInput
                                                id="outlined-login-showNewPass"
                                                onChange={onFormValueChange}
                                                type={showPassFlg.isShowNewPass ? 'text' : 'password'}
                                                value={formValue.newPass}
                                                name="newPass"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => onShowPassClick("isShowNewPass")}
                                                            onMouseDown={onMouseDownPass}
                                                            edge="end"
                                                        >
                                                            {showPassFlg.isShowNewPass ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                autoComplete="new-password"
                                                label={loginInfoLabel.newPassword}
                                                onKeyDown={onInputKeyDown}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                {/* new_password_retype_text_field */}
                                <Grid xs={12} sx={{ width: "100%" }}>
                                    <Box component="div" sx={{ mb: 2 }} >
                                        {validErrors.reEnteringPassError.map(obj =>
                                            <FormHelperText key={obj.type} sx={{ mb: 1 }}
                                                error={!Functions.isEmpty(validErrors.reEnteringPassError)}
                                            >
                                                {ValidErrorMessages.passwordValidationError(obj)}
                                            </FormHelperText>
                                        )}
                                        <FormControl sx={{ width: '100%' }} variant="outlined" required
                                            error={!Functions.isEmpty(validErrors.reEnteringPassError)}
                                        >
                                            <InputLabel htmlFor="outlined-login-showRetypePass">{loginInfoLabel.reEnteringPassword}</InputLabel>
                                            <OutlinedInput
                                                id="outlined-login-showRetypePass"
                                                onChange={onFormValueChange}
                                                type={showPassFlg.isShowRetypePass ? 'text' : 'password'}
                                                value={formValue.retypePass}
                                                name="retypePass"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            name="isShowRetypePass"
                                                            onClick={() => onShowPassClick("isShowRetypePass")}
                                                            onMouseDown={onMouseDownPass}
                                                            edge="end"
                                                        >
                                                            {showPassFlg.isShowRetypePass ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                autoComplete="new-password"
                                                label={loginInfoLabel.reEnteringPassword}
                                                onKeyDown={onInputKeyDown}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CommonButton onClick={onSendPassword} sx={{ textTransform: 'none' }}>{commonButtonLabel.confirm}</CommonButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box >
            </Container >
        </>
    );
}
