import * as Constants from '../Constants/Constants';

export default class ImageCardModel {
    constructor(dataObject = null, listType) {
        this.Id = 0; // int
        this.ProductId = 0; // int
        this.JPTitle = "";
        this.ENTitle = "";
        this.JPSubTitle = "";
        this.ENSubTitle = "";
        this.ImgFilePath = "";
        this.ImageObjectURL = "";
        this.WebsitesUrl = "";
        this.AddedDateTime = null; // date time
        this.IsArtist = false;
        this.IsEvent = false;
        this.IsArt = false;
        this.IsGoods = false;
        this.IsMyArt = false;

        if (dataObject !== null) {
            this.IsArtist = listType === Constants.LIST_TYPE_ARTIST;
            this.IsEvent = listType === Constants.LIST_TYPE_EVENT;
            this.IsArt = listType === Constants.LIST_TYPE_ART;
            this.IsGoods = listType === Constants.LIST_TYPE_GOODS;
            this.IsMyArt = listType === Constants.LIST_TYPE_MYART;
            if (this.IsArtist) {
                this.Id = Number(dataObject.ArtistID);
                this.JPTitle = dataObject.JPArtistDisplayName;
                this.ENTitle = dataObject.ENArtistDisplayName;
                this.ImgFilePath = dataObject.ArtistImageFilepath;
                this.ImageObjectURL = dataObject.ArtistImageObjectURL;
                if (dataObject.PickUpDateTime) {
                    this.AddedDateTime = dataObject.PickUpDateTime;
                } else if (dataObject.FollowDateTime) {
                    this.AddedDateTime = dataObject.FollowDateTime;
                } else if (dataObject.RegistrationDateTime) {
                    this.AddedDateTime = dataObject.RegistrationDateTime;
                }
            }
            else if (this.IsEvent) {
                this.Id = Number(dataObject.EventID);
                this.JPTitle = dataObject.JPEventTitle;
                this.ENTitle = dataObject.ENEventTitle;
                this.ImgFilePath = dataObject.EventImageFilepath;
                this.ImageObjectURL = dataObject.EventImageObjectURL;
                if (dataObject.PickUpDateTime) {
                    this.AddedDateTime = dataObject.PickUpDateTime;
                } else if (dataObject.FollowDateTime) {
                    this.AddedDateTime = dataObject.FollowDateTime;
                } else if (dataObject.RegistrationDateTime) {
                    this.AddedDateTime = dataObject.RegistrationDateTime;
                }
            }
            else if (this.IsArt) {
                this.Id = Number(dataObject.ProductID);
                this.JPTitle = dataObject.JPProductTitle;
                this.ENTitle = dataObject.ENProductTitle;
                this.JPSubTitle = dataObject.JPArtistDisplayName;
                this.ENSubTitle = dataObject.ENArtistDisplayName;
                this.ImgFilePath = dataObject.ProductImageFilepath;
                this.ImageObjectURL = dataObject.ProductImageObjectURL;
                if (dataObject.PickUpDateTime) {
                    this.AddedDateTime = dataObject.PickUpDateTime;
                } else if (dataObject.FollowDateTime) {
                    this.AddedDateTime = dataObject.FollowDateTime;
                } else if (dataObject.RegistrationDateTime) {
                    this.AddedDateTime = dataObject.RegistrationDateTime;
                }
            }
            else if (this.IsGoods) {
                this.Id = Number(dataObject.GoodsID);
                this.JPTitle = dataObject.JPGoodsName;
                this.ENTitle = dataObject.ENGoodsName;
                this.JPSubTitle = dataObject.JPGoodsDescription;
                this.ENSubTitle = dataObject.ENGoodsDescription;
                // this.ImgFilePath = dataObject.ProductImageFilepath;
                this.ImageObjectURL = dataObject.GoodsImageObjectURL;
                this.WebsitesUrl = dataObject.GoodsURL.URL;
                if (dataObject.RegistrationDateTime) {
                    this.AddedDateTime = dataObject.RegistrationDateTime;
                }
            }
            else if (this.IsMyArt) {
                this.Id = Number(dataObject.MyArtID);
                this.ProductId = Number(dataObject.ProductID);
                this.JPTitle = dataObject.JPProductTitle;
                this.ENTitle = dataObject.ENProductTitle;
                this.JPSubTitle = dataObject.JPArtistDisplayName;
                this.ENSubTitle = dataObject.ENArtistDisplayName;
                this.ImgFilePath = dataObject.ProductImageFilepath;
                this.ImageObjectURL = dataObject.ProductImageObjectURL;
                if (dataObject.OwnedDateTime) {
                    this.AddedDateTime = dataObject.OwnedDateTime;
                }
            }
        }
    }

    getTitle(lang = Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
        if (lang === Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return this.JPTitle;
        } else {
            return this.ENTitle;
        }
    }

    getSubTitle(lang = Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
        if (lang === Constants.LANGUAGE_CODE_TYPE_JAPANESE) {
            return this.JPSubTitle;
        } else {
            return this.ENSubTitle;
        }
    }
}
