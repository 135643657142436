import * as ActionTypes from "../Constants/ActionTypes";
import * as Constants from "../Constants/Constants";
import AppConfig from "../Utils/AppConfig";

const apiKey = process.env.REACT_APP_API_KEY;

export default class Actions {
    constructor(dispatch) {
        this.Dispatch = dispatch;
    }

    setUserAuth(userId, userSub, email) {
        this.Dispatch({
            type: ActionTypes.SET_USERAUTH,
            userId, userSub, email,
        });
    }

    setUserSub(userSub, email) {
        this.Dispatch({
            type: ActionTypes.SET_USERAUTH_USERSUB,
            userSub, email,
        });
    }

    setUserId(userId) {
        this.Dispatch({
            type: ActionTypes.SET_USERAUTH_USERID,
            userId,
        });
    }

    userInfoFetch(postData) {
        this.userInfoFetching(true, false);
        fetch(AppConfig.GetUserInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.userInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(userInfo => {
                this.setUserInfo(userInfo);
            })
            .catch(e => {
                this.userInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    userInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.USERINFO_ACT_FETCHING,
            loading, loadingError, statusCode
        });
    }

    setUserInfo(userInfo) {
        this.Dispatch({
            type: ActionTypes.SET_USERINFO,
            userInfo,
        });
    }

    setLanguage(language) {
        this.Dispatch({
            type: ActionTypes.SET_LANGUAGE,
            language,
        });
    }

    myArtListFetch(postData) {
        this.myArtListFetching(true, false);
        fetch(AppConfig.GetMyArtList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.myArtListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(myArtList => {
                this.setMyArtList(myArtList.MyArtList);
            })
            .catch(e => {
                this.myArtListFetching(false, true, Number(e.StatusCode));
            });
    }

    myArtListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.MYARTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setMyArtList(myArtList) {
        this.Dispatch({
            type: ActionTypes.SET_MYARTLIST,
            myArtList: myArtList,
        });
    }

    followContentsListFetch(postData) {
        this.followContentsListFetching(true, false);
        fetch(AppConfig.GetFollowContentsList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.followContentsListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(followContentsList => {
                this.setFollowContentsList(followContentsList);
            })
            .catch(e => {
                this.followContentsListFetching(false, true, Number(e.StatusCode));
            });
    }

    followContentsListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.FOLLOWCONTENTSLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setFollowContentsList(followContentsList) {
        this.Dispatch({
            type: ActionTypes.SET_FOLLOWCONTENTSLIST,
            followContentsList: followContentsList,
        });
    }

    pickUpContentsListFetch(postData) {
        this.pickUpContentsListFetching(true, false);
        fetch(AppConfig.GetPickUpContentsList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.pickUpContentsListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(pickUpContentsList => {
                this.setPickUpContentsList(pickUpContentsList);
            })
            .catch(e => {
                this.pickUpContentsListFetching(false, true, Number(e.StatusCode));
            });
    }

    pickUpContentsListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.PICKUPCONTENTSLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setPickUpContentsList(pickUpContentsList) {
        this.Dispatch({
            type: ActionTypes.SET_PICKUPCONTENTSLIST,
            pickUpContentsList: pickUpContentsList,
        });
    }

    newsListFetch(postData) {
        this.newsListFetching(true, false);
        fetch(AppConfig.GetNewsList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.newsListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(newsList => {
                this.setNewsList(newsList.NewsList);
            })
            .catch(e => {
                this.newsListFetching(false, true, Number(e.StatusCode));
            });
    }

    newsListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.NEWSLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setNewsList(newsList) {
        this.Dispatch({
            type: ActionTypes.SET_NEWSLIST,
            newsList: newsList,
        });
    }

    artistInfoFetch(postData) {
        this.artistInfoFetching(true, false);
        fetch(AppConfig.GetArtistInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.artistInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(artistInfo => {
                this.setArtistInfo(artistInfo);
            })
            .catch((e) => {
                this.artistInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    artistInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.ARTISTINFO_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setArtistInfo(artistInfo) {
        this.Dispatch({
            type: ActionTypes.SET_ARTISTINFO,
            artistInfo: artistInfo,
        });
    }

    relatedArtListFetch(postData) {
        this.relatedArtListFetching(true, false);
        fetch(AppConfig.GetRelatedArtList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.relatedArtListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(relatedArtList => {
                this.setRelatedArtList(relatedArtList.RelatedArtList);
            })
            .catch((e) => {
                this.relatedArtListFetching(false, true, Number(e.StatusCode));
            });
    }

    relatedArtListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.RELATEDARTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setRelatedArtList(relatedArtList) {
        this.Dispatch({
            type: ActionTypes.SET_RELATEDARTLIST,
            relatedArtList: relatedArtList,
        });
    }

    exhibitEventListFetch(postData) {
        this.exhibitEventListFetching(true, false);
        fetch(AppConfig.GetExhibitEventList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.exhibitEventListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(exhibitEventList => {
                this.setExhibitEventList(exhibitEventList.ExhibitEventList);
            })
            .catch((e) => {
                this.exhibitEventListFetching(false, true, Number(e.StatusCode));
            });
    }

    exhibitEventListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.EXHIBITEVENTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setExhibitEventList(exhibitEventList) {
        this.Dispatch({
            type: ActionTypes.SET_EXHIBITEVENTLIST,
            exhibitEventList: exhibitEventList,
        });
    }

    eventInfoFetch(postData) {
        this.eventInfoFetching(true, false);
        fetch(AppConfig.GetEventInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.eventInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(eventInfo => {
                this.setEventInfo(eventInfo);
            })
            .catch((e) => {
                this.eventInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    eventInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.EVENTINFO_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setEventInfo(eventInfo) {
        this.Dispatch({
            type: ActionTypes.SET_EVENTINFO,
            eventInfo: eventInfo,
        });
    }

    exhibitArtListFetch(postData) {
        this.exhibitArtListFetching(true, false);
        fetch(AppConfig.GetExhibitArtList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.exhibitArtListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(exhibitArtList => {
                this.setExhibitArtList(exhibitArtList.ExhibitArtList);
            })
            .catch((e) => {
                this.exhibitArtListFetching(false, true, Number(e.StatusCode));
            });
    }

    exhibitArtListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.EXHIBITARTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setExhibitArtList(exhibitArtList) {
        this.Dispatch({
            type: ActionTypes.SET_EXHIBITARTLIST,
            exhibitArtList: exhibitArtList,
        });
    }

    exhibitArtistListFetch(postData) {
        this.exhibitArtistListFetching(true, false);
        fetch(AppConfig.GetExhibitArtistList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.exhibitArtistListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(exhibitArtistList => {
                this.setExhibitArtistList(exhibitArtistList.ExhibitArtistList);
            })
            .catch((e) => {
                this.exhibitArtistListFetching(false, true, Number(e.StatusCode));
            });
    }

    exhibitArtistListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.EXHIBITARTISTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setExhibitArtistList(exhibitArtistList) {
        this.Dispatch({
            type: ActionTypes.SET_EXHIBITARTISTLIST,
            exhibitArtistList: exhibitArtistList,
        });
    }

    artInfoFetch(postData) {
        this.artInfoFetching(true, false);
        fetch(AppConfig.GetArtInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.artInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(artInfo => {
                this.setArtInfo(artInfo);
            })
            .catch(e => {
                this.artInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    artInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.ARTINFO_ACT_FETCHING,
            loading, loadingError, statusCode
        });
    }

    setArtInfo(artInfo) {
        this.Dispatch({
            type: ActionTypes.SET_ARTINFO,
            artInfo: artInfo,
        });
    }

    goodsListFetch(postData) {
        this.goodsListFetching(true, false);
        fetch(AppConfig.GetGoodsList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.goodsListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(goodsList => {
                this.setGoodsList(goodsList.GoodsList);
            })
            .catch(e => {
                this.goodsListFetching(false, true, Number(e.StatusCode));
            });
    }

    goodsListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.GOODSLIST_ACT_FETCHING,
            loading, loadingError, statusCode
        });
    }

    setGoodsList(goodsList) {
        this.Dispatch({
            type: ActionTypes.SET_GOODSLIST,
            goodsList: goodsList,
        });
    }

    myArtInfoFetch(postData) {
        this.myArtInfoFetching(true, false);
        fetch(AppConfig.GetMyArtInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.myArtInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(myArtInfo => {
                this.setMyArtInfo(myArtInfo);
            })
            .catch(e => {
                this.myArtInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    myArtInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.MYARTINFO_ACT_FETCHING,
            loading, loadingError, statusCode
        });
    }

    setMyArtInfo(myArtInfo) {
        this.Dispatch({
            type: ActionTypes.SET_MYARTINFO,
            myArtInfo: myArtInfo,
        });
    }

    userPrivilegeInfoFetch(postData) {
        this.userPrivilegeInfoFetching(true, false);
        fetch(AppConfig.GetUserPrivilegeInfo, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.userPrivilegeInfoFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(userPrivilegeInfo => {
                this.setUserPrivilegeInfo(userPrivilegeInfo.UserPrivilegeInfoList);
            })
            .catch(e => {
                this.userPrivilegeInfoFetching(false, true, Number(e.StatusCode));
            });
    }

    userPrivilegeInfoFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.USERPRIVILEGEINFO_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setUserPrivilegeInfo(userPrivilegeInfo) {
        this.Dispatch({
            type: ActionTypes.SET_USERPRIVILEGEINFO,
            userPrivilegeInfo: userPrivilegeInfo,
        });
    }

    privilegePermissionHistoryFetch(postData) {
        this.privilegePermissionHistoryFetching(true, false);
        fetch(AppConfig.GetPrivilegePermissionHistory, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.privilegePermissionHistoryFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(privilegePermissionHistory => {
                this.setPrivilegePermissionHistory(privilegePermissionHistory.PrivilegePermissionHistory);
            })
            .catch(e => {
                this.privilegePermissionHistoryFetching(false, true, Number(e.StatusCode));
            });
    }

    privilegePermissionHistoryFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.PRIVILEGEPERMISSIOMHISTORY_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setPrivilegePermissionHistory(privilegePermissionHistory) {
        this.Dispatch({
            type: ActionTypes.SET_PRIVILEGEPERMISSIOMHISTORY,
            privilegePermissionHistory: privilegePermissionHistory,
        });
    }

    searchResultListFetch(postData) {
        this.searchResultListFetching(true, false);
        fetch(AppConfig.GetSearchResultList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.searchResultListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(searchResultList => {
                this.setSearchResultListFetching(searchResultList);
            })
            .catch((e) => {
                this.searchResultListFetching(false, true, Number(e.StatusCode));
            });
    }

    searchResultListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.SEARCHRESULTLIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setSearchResultListFetching(searchResultList) {
        this.Dispatch({
            type: ActionTypes.SET_SEARCHRESULTLIST,
            searchResultList: searchResultList,
        });
    }

    insertFollowContentsFetch(postData) {
        this.insertFollowContentsFetching(true, false);
        fetch(AppConfig.InsertFollowContents, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.insertFollowContentsFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(json => {
                if (json.Status === "success") {
                    this.addFollowContentsList(postData);
                } else {
                    throw new Error();
                }
            })
            .catch((e) => {
                this.insertFollowContentsFetching(false, true, Number(e.StatusCode));
            });
    }

    insertFollowContentsFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.INSERTFOLLOWCONTENTS_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    // TODO: 現状の実装状況に合わせているので、APIが修正されたら直すこと
    addFollowContentsList(postData) {
        this.Dispatch({
            type: ActionTypes.ADD_FOLLOWCONTENTSLIST,
            postData,
        });
    }

    deleteFollowContentsList(postData) {
        this.Dispatch({
            type: ActionTypes.DELETE_FOLLOWCONTENTSLIST,
            postData,
        });
    }

    deleteFollowContentsFetch(postData) {
        this.deleteFollowContentsFetching(true, false);
        fetch(AppConfig.DeleteFollowContents, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.deleteFollowContentsFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(json => {
                if (json.Status === "success") {
                    this.deleteFollowContentsList(postData);
                } else {
                    throw new Error();
                }
            })
            .catch((e) => {
                this.deleteFollowContentsFetching(false, true, Number(e.StatusCode));
            });
    }

    deleteFollowContentsFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.DELETEFOLLOWCONTENTS_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }
    countoryCodeListFetch() {
        const postData = {
            CodeType: Constants.GENERAL_CODE_TYPE_COUNTRY,
        };

        this.countoryCodeListFetching(true, false);
        fetch(AppConfig.GetCodeList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.countoryCodeListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(generalCodeList => {
                this.setCountryCodeList(generalCodeList.CodeList);
            })
            .catch(e => {
                this.countoryCodeListFetching(false, true, Number(e.StatusCode));
            });
    }

    countoryCodeListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.COUNTRYCODELIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setCountryCodeList(generalCodeList) {
        this.Dispatch({
            type: ActionTypes.SET_COUNTRY_CODE_LIST,
            generalCodeList,
        });
    }

    prefectureCodeListFetch() {
        const postData = {
            CodeType: Constants.GENERAL_CODE_TYPE_PREFECTURES,
        };

        this.prefectureCodeListFetching(true, false);
        fetch(AppConfig.GetCodeList, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                this.prefectureCodeListFetching(false, false);
                return response;
            })
            .then(response => response.json())
            .then(prefectureCodeList => {
                this.setPrefecureCodeList(prefectureCodeList.CodeList);
            })
            .catch(e => {
                this.prefectureCodeListFetching(false, true, Number(e.StatusCode));
            });
    }

    prefectureCodeListFetching(loading, loadingError, statusCode = 0) {
        this.Dispatch({
            type: ActionTypes.PREFECTURECODELIST_ACT_FETCHING,
            loading, loadingError, statusCode,
        });
    }

    setPrefecureCodeList(prefectureCodeList) {
        this.Dispatch({
            type: ActionTypes.SET_PREFECTURE_CODE_LIST,
            prefectureCodeList,
        });
    }

    setSearchResultListHistory(currentPage, conditions = null) {
        this.Dispatch({
            type: ActionTypes.SET_SEARCHRESULTLIST_HISTORY,
            currentPage,
            conditions,
        });
    }

    setFollowContentsListHistory(currentPage, conditions = null) {
        this.Dispatch({
            type: ActionTypes.SET_FOLLOWCONTENTSLIST_HISTORY,
            currentPage,
            conditions,
        });
    }

    setMyArtListHistory(currentPage, conditions = null) {
        this.Dispatch({
            type: ActionTypes.SET_MYARTLIST_HISTORY,
            currentPage,
            conditions,
        });
    }
}
