import 'dayjs/locale/ja';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ArtistDetail from './Components/Details/ArtistDetail';
import EventDetail from './Components/Details/EventDetail';
import FavouritesList from './Components/Lists/FavouritesList';
import Footer from './Components/CommonParts/Footer';
import Header from './Components/CommonParts/Header';
import McaDetail from './Components/Details/McaDetail';
import PurchasedMcaDetail from './Components/Details/PurchasedMcaDetail';
import PurchasedMcaList from './Components/Lists/PurchasedMcaList';
import SearchResultList from './Components/Lists/SearchResultList';
import SingupComfirm from './Components/Signup/SingupComfirm';
import SingupInput from './Components/Signup/SingupInput';
import Top from './Components/Top/Top';
import UserInformation from './Components/UserInfo/UserInformation';
import UserInformationEdit from './Components/UserInfo/UserInformationEdit';
import EditEmail from './Components/UserInfo/EditEmail';
import EditEmailCheckAuthcode from './Components/UserInfo/EditEmailCheckAuthcode';
import EditPassword from './Components/UserInfo/EditPassword';
import EditComplete from './Components/UserInfo/EditComplete';
import SigninStatusCheckRoute from './Components/CustumRoutes/SigninStatusCheckRoute'
import './css/App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

let responsiveDarkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#212121',
            paper: '#000000',
        },
        text: {
            primary: '#fafafa',
        },
    },
    typography: {
        fontFamily: ['Arial', '"Noto Sans JP"', 'sans-serif'].join(','),
    },
});
responsiveDarkTheme = responsiveFontSizes(responsiveDarkTheme);

export default function App() {
    // 遷移時に画面最上部から描画
    function ScrollToTop() {
        const { pathname } = useLocation()

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname])

        return null
    }

    return (
        <ThemeProvider theme={responsiveDarkTheme}>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <CssBaseline />
                    <BrowserRouter>
                        <ScrollToTop />
                        <Header />
                        {/* 各ページへのルート設定 */}
                        <Routes>
                            <Route element={<SigninStatusCheckRoute />}>
                                <Route path="/" element={<Top />} />
                                <Route path="/account/purchasedMca" element={<PurchasedMcaList />} />
                                <Route path="/account/purchasedMca/:id" element={<PurchasedMcaDetail />} />
                                <Route path="/account/favorites" element={<FavouritesList />} />
                                <Route path='/account/userInfo' element={<UserInformation />} />
                                <Route path='/account/userInfo/edit' element={<UserInformationEdit />} />
                                <Route path="/account/userInfo/edit/email" element={<EditEmail />} />
                                <Route path="/account/userInfo/edit/email/checkAuthcode" element={<EditEmailCheckAuthcode />} />
                                <Route path="/account/userInfo/edit/password" element={<EditPassword />} />
                                <Route path="/account/userInfo/edit/complete" element={<EditComplete />} />
                                <Route path="/art/:id" element={<McaDetail />} />
                                <Route path="/artist/:id" element={<ArtistDetail />} />
                                <Route path="/event/:id" element={<EventDetail />} />
                                <Route path="/search" element={<SearchResultList />} />
                                <Route path='/singup/input' element={<SingupInput />} />
                                <Route path='/singup/comfirm' element={<SingupComfirm />} />
                                <Route path="*" element={<h1>Not Found Page</h1>} />
                            </Route>
                        </Routes>
                        <Footer />
                    </BrowserRouter>
                </LocalizationProvider>
            </StyledEngineProvider>
        </ThemeProvider>
    );
}
