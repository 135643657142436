import { Alert, AlertTitle, Box, Checkbox, Container, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Pagination, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Loader from "../CommonParts/Loader";
import { useAppContext } from "../../Contexts/AppContext";
import GridDisplay from './Parts/GridDisplay';
import { useNavigationType } from 'react-router-dom';

export default function FavouritesList() {
    const { Actions, Reducer, LanguageResponsiveMessages, ApiErrorMessages } = useAppContext();
    const type = useNavigationType();

    const [allFavouriteItems, setAllFavouriteItems] = useState([]);
    const [displayFavouritesList, setDisplayFavouritesList] = useState([]);
    const [isTimeout, setIsTimeout] = useState(false);

    const itemsPerPage = 12;
    const maximumPages = Math.ceil(allFavouriteItems.length / itemsPerPage);

    useEffect(() => {
        Actions.followContentsListFetch({ UserID: Number(Reducer.userAuth.userId) });
        return () => {
            Actions.followContentsListFetching(false, false);
        }
    }, []);

    useEffect(() => {
        if (type !== "POP") {
            Actions.setFollowContentsListHistory(1, []);
            setAllFavouriteItems(Reducer.followContentsList);
            setDisplayFavouritesList(Reducer.followContentsList.slice(0, itemsPerPage));
        } else {
            const newDisplayFavouritesList = filterList(Reducer.followContentsListHistory.conditions);
            setAllFavouriteItems(newDisplayFavouritesList);
            setDisplayFavouritesList(
                newDisplayFavouritesList.slice(
                    ((Reducer.followContentsListHistory.currentPage - 1) * itemsPerPage), Reducer.followContentsListHistory.currentPage * itemsPerPage
                ));
        }
    }, [Reducer.followContentsList]);

    let artCount = 0;
    let artistCount = 0;
    let eventCount = 0;
    Reducer.followContentsList.forEach(element => {
        if (element.IsArt) {
            artCount++
        } else if (element.IsArtist) {
            artistCount++
        } else if (element.IsEvent) {
            eventCount++
        }
    });

    const commonLabel = LanguageResponsiveMessages.commonLabel;
    const filteringConditions = [
        { key: 1, value: 1, text: commonLabel.art },
        { key: 2, value: 2, text: commonLabel.artist },
        { key: 3, value: 3, text: commonLabel.event },
    ];

    function onConditionsChange(evn) {
        const newDisplayFavouritesList = filterList(evn.target.value);

        Actions.setFollowContentsListHistory(1, evn.target.value);
        setAllFavouriteItems(newDisplayFavouritesList);
        setDisplayFavouritesList(newDisplayFavouritesList.slice(0, itemsPerPage));

    }

    function onPageChange(evn, index) {
        Actions.setFollowContentsListHistory(index);
        setDisplayFavouritesList(allFavouriteItems.slice(((index - 1) * itemsPerPage), index * itemsPerPage));
    }


    function renderConditionValue(item) {
        switch (item) {
            case commonLabel.art:
                return item + "(" + artCount + ")";

            case commonLabel.artist:
                return item + "(" + artistCount + ")";

            case commonLabel.event:
                return item + "(" + eventCount + ")";

            default:
                return item + "(0)";
        }
    }

    function filterList(selectCondition) {
        const condtionString = selectCondition.join(',');
        let newDisplayFavouritesList = [];

        if (condtionString.includes(commonLabel.art)) {
            newDisplayFavouritesList = newDisplayFavouritesList.concat(Reducer.followContentsList.filter(x => x.IsArt));
        }
        if (condtionString.includes(commonLabel.artist)) {
            newDisplayFavouritesList = newDisplayFavouritesList.concat(Reducer.followContentsList.filter(x => x.IsArtist));
        }
        if (condtionString.includes(commonLabel.event)) {
            newDisplayFavouritesList = newDisplayFavouritesList.concat(Reducer.followContentsList.filter(x => x.IsEvent));
        }
        if (condtionString == "") {
            newDisplayFavouritesList = Reducer.followContentsList;
        }

        return newDisplayFavouritesList;
    }

    const errorMessage =
        Reducer.followContentsListFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {ApiErrorMessages.apiMessage(Reducer.followContentsListFetch.statusCode, "Reducer.followContentsListFetch")}
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (Reducer.followContentsListFetch.loading) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [Reducer.followContentsListFetch.loading]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={Reducer.followContentsListFetch.loading && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Follow List
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel>{commonLabel.filters}</InputLabel>
                            <Select
                                multiple
                                value={Reducer.followContentsListHistory.conditions}
                                onChange={onConditionsChange}
                                renderValue={selected => {
                                    const renderValue = selected.map(item => renderConditionValue(item));
                                    return renderValue.join(', ');
                                }}
                                input={<OutlinedInput label={commonLabel.filters} />}
                            >
                                {filteringConditions.map(item => {
                                    const renderValue = renderConditionValue(item.text);
                                    return (
                                        <MenuItem key={item.key} value={item.text}>
                                            <Checkbox checked={Reducer.followContentsListHistory.conditions.indexOf(item.text) > -1} />
                                            <ListItemText primary={renderValue} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {Reducer.followContentsListFetch.loading
                        ? null
                        : displayFavouritesList.length === 0
                            ? <Typography variant="body1">
                                {LanguageResponsiveMessages.contentNotFound}
                            </Typography >
                            : <>
                                <GridDisplay mcaList={displayFavouritesList} />

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <Pagination
                                        count={maximumPages}
                                        size="large"
                                        page={Reducer.followContentsListHistory.currentPage}
                                        onChange={onPageChange}
                                    />
                                </div>
                            </>
                    }
                </Box>
            </Container>
        </>
    )
}
