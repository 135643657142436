import { Alert, AlertTitle, Autocomplete, Box, Container, Divider, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../Contexts/AppContext';
import UserInfoEditModel from '../../Model/UserInfoEditModel';
import AppConfig from "../../Utils/AppConfig";
import { Functions } from '../../Utils/Functions';
import { ButtonToOtherPage, CommonButton } from '../CommonParts/CommonButton';
import Loader from "../CommonParts/Loader";

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlags = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

export default function UserInformationEdit() {
    const { Reducer, Actions, LanguageResponsiveMessages, ValidErrorMessages, ApiErrorMessages } = useAppContext();
    const [loadingFlags, setLoadingFlags] = useState(initialLoadingFlags);
    const [formValue, setFormValue] = useState(() => UserInfoEditModel.parse(Reducer.userInfo));
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (Reducer.userInfo.UserID == 0) {
            navigate("/account/userInfo");
        } else {
            if (Reducer.countryCodeList.length === 0) {
                Actions.countoryCodeListFetch();
            }
            if (Reducer.prefectureCodeList.length === 0) {
                Actions.prefectureCodeListFetch();
            }
        }
        return () => {
            Actions.countoryCodeListFetching(false, false);
            Actions.prefectureCodeListFetching(false, false);
        }
    }, []);

    const areaOptions = Reducer.countryCodeList.sort((a, b) => a.SortOrder - b.SortOrder)
        .map(x => ({ id: x.Code, label: x.JPCodeDescription, }));
    const prefectureOptions = Reducer.prefectureCodeList.sort((a, b) => a.SortOrder - b.SortOrder)
        .map(x => ({ id: x.Code, label: x.JPCodeDescription, }));

    const provinceCodeOption = prefectureOptions.find(x => x.id == formValue.ProvinceCode) ?? null;
    const countryCodeOption = areaOptions.find(x => x.id == formValue.CountryCode) ?? null;

    function onInputChange(evn) {
        const { value, name } = evn.target;
        setFormValue(prev => {
            const newFormValue = prev.clone();
            newFormValue[name] = value;
            return newFormValue;
        });
    }

    // function onBirthDayChange(evn) {
    //     setFormValue(prev => {
    //         const newFormValue = prev.clone();
    //         newFormValue.BirthDate = evn
    //         return newFormValue;
    //     });
    // }

    function onCountryChange(evn, value) {
        setFormValue(prev => {
            const newFormValue = prev.clone();
            newFormValue.CountryCode = value?.id ?? "";
            return newFormValue;
        });
    }

    function onPrefectureChange(evn, value) {
        setFormValue(prev => {
            const newFormValue = prev.clone();
            newFormValue.ProvinceCode = value?.id ?? "";
            return newFormValue;
        });
    }

    function onSave() {
        const errors = formValue.validate();
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            const postData = formValue.toSaveData();
            setLoadingFlags({ loading: true, loadingError: false, statusCode: 0 });
            fetch(AppConfig.UpdateUserInfo, {
                method: "post",
                headers: {
                    "x-api-key": apiKey,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(postData),
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json()
                            .then(function (err) {
                                throw err
                            });
                    }
                    setLoadingFlags({ loading: false, loadingError: false, statusCode: 0 });
                    return response;
                })
                .then(response => response.json())
                .then(json => {
                    if (json.Status == "success") {
                        navigate("/account/userInfo");
                    } else {
                        throw new Error();
                    }
                })
                .catch((e) => {
                    setLoadingFlags({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
                });
        }
    }

    const loadingFlagKeys = ["countoryCodeListFetch", "prefectureCodeListFetch"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag || loadingFlags.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
                {loadingFlags.statusCode ?
                    ApiErrorMessages.apiMessage(loadingFlags.statusCode, "UserInformationEdit.onSave")
                    : null
                }
            </Alert>
            : null;

    const label = LanguageResponsiveMessages.userInformationLabel;
    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <Container maxWidth='lg'>
            <Loader open={loadingFlag || loadingFlags.loading} />
            {errorMessage}
            <Box component='div' className='titleStyle'>
                <Typography variant='h3' mb={2}>
                    User information
                </Typography>
                <Paper>
                    <Grid container justifyContent='center' direction='column' sx={{ padding: 4 }}>
                        <Grid container>
                            <Grid item>
                                <Typography variant='overline' sx={{ marginRight: 1, textTransform: 'none' }}>
                                    {loginInfoLabel.loginId}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='overline' className='assist-message' sx={{ textTransform: 'none' }}>
                                    {LanguageResponsiveMessages.sendConfirmationURL}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className='row-pair'>
                            <Typography
                                sx={{ flexGrow: 1, wordBreak: 'break-all', alignSelf: "center", marginRight: 1, }}>
                                {Reducer.userInfo.Email}
                            </Typography>
                            <Grid>
                                <ButtonToOtherPage label={commonButtonLabel.edit} to='/account/userInfo/edit/email' state={{ currentEmail: Reducer.userInfo.Email }} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant='overline' sx={{ marginRight: 1, textTransform: 'none' }}>
                                    {loginInfoLabel.password}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='overline' className='assist-message' sx={{ textTransform: 'none' }}>
                                    {LanguageResponsiveMessages.sendResetURL}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className='row-pair'>
                            <Grid>
                                <Typography sx={{ wordWrap: 'break-word', alignSelf: "center", marginRight: 1, }}>
                                    ******
                                </Typography>
                            </Grid>
                            <Grid>
                                <ButtonToOtherPage label={commonButtonLabel.edit} to='/account/userInfo/edit/password' state={{ currentEmail: Reducer.userInfo.Email }} />
                            </Grid>
                        </Grid>
                        <Divider sx={{ mb: 3, mt: 2 }} />
                        {/* {Reducer.userInfo.BirthDate
                            ? <>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant='overline' sx={{ marginRight: 1, textTransform: 'none' }}>
                                            {label.birthDate}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='overline' className='assist-message' sx={{ textTransform: 'none' }}>
                                            {LanguageResponsiveMessages.birthDateCannotBeChanged}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ mb: 2 }}>
                                    {Functions.formatDateBySlash(Reducer.userInfo.BirthDate, Reducer.language)}
                                </Typography>
                                <Divider sx={{ mb: 3 }} />
                            </>
                            : <>
                                <Divider sx={{ mb: 3, mt: 2 }} />
                                <DatePicker
                                    className={!Functions.isEmpty(errors.BirthDate) ? "Error-Zone" : ""}
                                    sx={{ mb: !Functions.isEmpty(errors.BirthDate) ? 0 : 2 }}
                                    label={
                                        <span className={!Functions.isEmpty(errors.BirthDate) ? "spanError-Zone" : ""}>
                                            {label.birthDate} {LanguageResponsiveMessages.birthDateCannotBeChanged}
                                        </span>
                                    }
                                    openTo="year" onChange={onBirthDayChange}
                                />
                                {!Functions.isEmpty(errors.BirthDate) ? <FormHelperText error sx={{ mb: 2, ml: 2 }}>{errors.BirthDate}</FormHelperText> : ""}
                            </>
                        } */}
                        <DropdownComponent label={label.country} mbStyle="16px"
                            options={areaOptions} value={countryCodeOption} onChange={onCountryChange} />
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: 2.5, mb: 2 }}>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    error={!Functions.isEmpty(errors.LastName)}
                                    className='account-input' label={label.lastName}
                                    value={formValue.LastName} onChange={onInputChange} name="LastName"
                                    helperText={errors.LastName
                                        ? ValidErrorMessages.nameValidationError(errors.LastName)
                                        : null
                                    }
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    error={!Functions.isEmpty(errors.FirstName)}
                                    className='account-input' label={label.firstName}
                                    value={formValue.FirstName} onChange={onInputChange} name="FirstName"
                                    helperText={errors.FirstName
                                        ? ValidErrorMessages.nameValidationError(errors.FirstName)
                                        : null
                                    }
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            className='account-input' label={label.phoneNumber} sx={{ mb: 2 }}
                            value={formValue.PhoneNumber} onChange={onInputChange} name="PhoneNumber"
                            inputProps={{ maxLength: 100 }}
                        />
                        <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 2 }}>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    error={!Functions.isEmpty(errors.FirstZipCode)}
                                    className='account-input' label={label.zipCode}
                                    value={formValue.FirstZipCode} onChange={onInputChange} name="FirstZipCode"
                                    helperText={errors.FirstZipCode
                                        ? ValidErrorMessages.zipCodeValidationError(errors.FirstZipCode)
                                        : null
                                    }
                                />
                            </Grid>
                            <Grid item xs="auto" sx={{ margin: 1, }}>-</Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    error={!Functions.isEmpty(errors.LastZipCode)}
                                    className='account-input' label={label.zipCode}
                                    value={formValue.LastZipCode} onChange={onInputChange} name="LastZipCode"
                                    helperText={errors.LastZipCode
                                        ? ValidErrorMessages.zipCodeValidationError(errors.LastZipCode)
                                        : null
                                    }
                                />
                            </Grid>
                        </Grid>
                        <DropdownComponent label={label.prefecture} mbStyle="16px"
                            options={prefectureOptions} value={provinceCodeOption} onChange={onPrefectureChange} />
                        <TextField className='account-input' label={label.city} sx={{ mb: 2 }}
                            value={formValue.City} onChange={onInputChange} name="City" inputProps={{ maxLength: 100 }} />
                        <TextField className='account-input' label={label.cityBlock} sx={{ mb: 2 }}
                            value={formValue.AddressLine1} onChange={onInputChange} name="AddressLine1" inputProps={{ maxLength: 100 }} />
                        <TextField className='account-input' label={label.buildingName} sx={{ mb: 2 }}
                            value={formValue.AddressLine2} onChange={onInputChange} name="AddressLine2" inputProps={{ maxLength: 100 }} />
                        <Grid container justifyContent='center' sx={{ paddingBottom: 0 }}>
                            <CommonButton onClick={onSave} sx={{ textTransform: 'none' }}>{commonButtonLabel.save}</CommonButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

function DropdownComponent({ label, options, mbStyle, value, onChange }) {
    return <>
        <Autocomplete
            sx={{ mb: mbStyle }}
            value={value}
            className='account-input'
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={onChange}
        />
    </>;
}
