// Reducerで行う処理のキー
export const USERINFO_ACT_FETCHING = "USERINFO_ACT_FETCHING";
export const MYARTLIST_ACT_FETCHING = "MYARTLIST_ACT_FETCHING";
export const FOLLOWCONTENTSLIST_ACT_FETCHING = "FOLLOWCONTENTSLIST_ACT_FETCHING";
export const PICKUPCONTENTSLIST_ACT_FETCHING = "PICKUPCONTENTSLIST_ACT_FETCHING";
export const NEWSLIST_ACT_FETCHING = "NEWSLIST_ACT_FETCHING";
export const ARTISTINFO_ACT_FETCHING = "ARTISTINFO_ACT_FETCHING";
export const RELATEDARTLIST_ACT_FETCHING = "RELATEDARTLIST_ACT_FETCHING";
export const EXHIBITEVENTLIST_ACT_FETCHING = "EXHIBITEVENTLIST_ACT_FETCHING";
export const EVENTINFO_ACT_FETCHING = "EVENTINFO_ACT_FETCHING";
export const EXHIBITARTLIST_ACT_FETCHING = "EXHIBITARTLIST_ACT_FETCHING";
export const EXHIBITARTISTLIST_ACT_FETCHING = "EXHIBITARTISTLIST_ACT_FETCHING";
export const ARTINFO_ACT_FETCHING = "ARTINFO_ACT_FETCHING";
export const GOODSLIST_ACT_FETCHING = "GOODSLIST_ACT_FETCHING";
export const MYARTINFO_ACT_FETCHING = "MYARTINFO_ACT_FETCHING";
export const USERPRIVILEGEINFO_ACT_FETCHING = "USERPRIVILEGEINFO_ACT_FETCHING";
export const PRIVILEGEPERMISSIOMHISTORY_ACT_FETCHING = "PRIVILEGEPERMISSIOMHISTORY_ACT_FETCHING";
export const INSERTFOLLOWCONTENTS_ACT_FETCHING = "INSERTFOLLOWCONTENTS_ACT_FETCHING";
export const DELETEFOLLOWCONTENTS_ACT_FETCHING = "DELETEFOLLOWCONTENTS_ACT_FETCHING";
export const SEARCHRESULTLIST_ACT_FETCHING = "SEARCHRESULTLIST_ACT_FETCHING";
export const COUNTRYCODELIST_ACT_FETCHING = "COUNTRYCODELIST_ACT_FETCHING";
export const PREFECTURECODELIST_ACT_FETCHING = "PREFECTURECODELIST_ACT_FETCHING";

export const SET_USERAUTH = "SET_USERAUTH";
export const SET_USERAUTH_TOKENS = "SET_USERAUTH_TOKENS";
export const SET_USERAUTH_USERSUB = "SET_USERAUTH_USERSUB";
export const SET_USERAUTH_USERID = "SET_USERAUTH_USERID";
export const SET_USERINFO = "SET_USERINFO";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_MYARTLIST = "SET_MYARTLIST";
export const SET_FOLLOWCONTENTSLIST = "SET_FOLLOWCONTENTSLIST";
export const ADD_FOLLOWCONTENTSLIST = "ADD_FOLLOWCONTENTSLIST";
export const DELETE_FOLLOWCONTENTSLIST = "DELETE_FOLLOWCONTENTSLIST";
export const SET_PICKUPCONTENTSLIST = "SET_PICKUPCONTENTSLIST";
export const SET_NEWSLIST = "SET_NEWSLIST";
export const SET_ARTISTINFO = "SET_ARTISTINFO";
export const SET_RELATEDARTLIST = "SET_RELATEDARTLIST";
export const SET_EXHIBITEVENTLIST = "SET_EXHIBITEVENTLIST";
export const SET_EVENTINFO = "SET_EVENTINFO";
export const SET_EXHIBITARTLIST = "SET_EXHIBITARTLIST";
export const SET_EXHIBITARTISTLIST = "SET_EXHIBITARTISTLIST";
export const SET_ARTINFO = "SET_ARTINFO";
export const SET_GOODSLIST = "SET_GOODSLIST";
export const SET_MYARTINFO = "SET_MYARTINFO";
export const SET_USERPRIVILEGEINFO = "SET_USERPRIVILEGEINFO";
export const SET_PRIVILEGEPERMISSIOMHISTORY = "SET_PRIVILEGEPERMISSIOMHISTORY";
export const SET_SEARCHRESULTLIST = "SET_SEARCHRESULTLIST";
export const SET_COUNTRY_CODE_LIST = "SET_COUNTRY_CODE_LIST";
export const SET_PREFECTURE_CODE_LIST = "SET_PREFECTURE_CODE_LIST";
export const SET_SEARCHRESULTLIST_HISTORY = "SET_SEARCHRESULTLIST_HISTORY";
export const SET_FOLLOWCONTENTSLIST_HISTORY = "SET_FOLLOWCONTENTSLIST_HISTORY";
export const SET_MYARTLIST_HISTORY = "SET_MYARTLIST_HISTORY";
