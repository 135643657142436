import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert, AlertTitle, Box, Container, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from "../../Contexts/AppContext";
import { Functions } from "../../Utils/Functions";
import { ButtonToOtherPage } from '../CommonParts/CommonButton';
import ImageScroller from '../CommonParts/ImageScroller';
import Loader from '../CommonParts/Loader';

export default function Top() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const [newsViewAllButtonHideFlag, setNewsViewAllButtonHideFlag] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        Actions.myArtListFetch({ UserID: Number(Reducer.userAuth.userId), Count: 10 });
        Actions.followContentsListFetch({ UserID: Number(Reducer.userAuth.userId), Count: 10 });
        Actions.pickUpContentsListFetch({ Count: 10 })
        Actions.newsListFetch({ Count: 3 });
        return () => {
            Actions.myArtListFetching(false, false);
            Actions.followContentsListFetching(false, false);
            Actions.pickUpContentsListFetching(false, false);
            Actions.newsListFetching(false, false);
        }
    }, []);

    function newsViewAllClick() {
        Actions.newsListFetch({});
        setNewsViewAllButtonHideFlag(true);
    }

    const dispNewsCnt = 10; // お知らせ表示件数

    const boxStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' };
    const imgColumnStyle = { maxWidth: '100%', width: '100%', mb: 2 };

    const loadingFlagKeys = ["myArtListFetch", "followContentsListFetch", "pickUpContentsListFetch", "newsListFetch"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (loadingFlag) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [loadingFlag]);

    return (
        <>
            <Container maxWidth="lg" sx={{ mb: 10 }}>
                <Loader open={loadingFlag && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        My Art
                    </Typography>
                    <Box sx={boxStyle}>
                        {Reducer.myArtList.length === 0
                            ? null
                            : <>
                                <Box sx={imgColumnStyle}>
                                    <ImageScroller imgCardList={Reducer.myArtList} />
                                </Box>
                                <ButtonToOtherPage label="VIEW ALL" to='/account/purchasedMca' />
                            </>
                        }
                    </Box>
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Follow
                    </Typography>
                    <Box sx={boxStyle}>
                        {Reducer.followContentsList.length === 0
                            ? null
                            : <>
                                <Box sx={imgColumnStyle}>
                                    <ImageScroller imgCardList={Reducer.followContentsList} />
                                </Box>
                                <ButtonToOtherPage label="VIEW ALL" to='/account/favorites' />
                            </>
                        }
                    </Box>
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Pick Up
                    </Typography>
                    <Box sx={boxStyle}>
                        {Reducer.pickUpContentsList.length === 0
                            ? null
                            :
                            <Box sx={imgColumnStyle}>
                                <ImageScroller imgCardList={Reducer.pickUpContentsList} />
                            </Box>
                        }
                    </Box>
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        News
                    </Typography>
                    <Box sx={boxStyle}>
                        <List sx={{ width: '100%' }}>
                            {Reducer.newsList.slice(0, dispNewsCnt).map(news => (
                                <>
                                    <ListItem disablePadding component={Link} sx={{ color: '#cccccc' }} to={news.NewsURL.length !== 0 ? news.NewsURL[0].URL : null}>
                                        <ListItemButton sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                                                <Typography variant="body2" component="p" sx={{ color: '#cccccc', mr: { md: 2 } }}>
                                                    {Functions.formatDateBySlash(news.PublicationDateTime, Reducer.language)}
                                                </Typography>
                                                <Typography variant="subtitle2" component="p">
                                                    {news.getNewsTitle(Reducer.language)}
                                                </Typography>
                                            </Box>
                                            <ArrowForwardIosIcon fontSize="small" sx={{ color: '#cccccc' }} />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                        <div style={{ display: newsViewAllButtonHideFlag ? "none" : "block" }}>
                            <ButtonToOtherPage label="VIEW ALL" onClick={newsViewAllClick} />
                        </div>
                    </Box>
                </Box>
            </Container >
        </>
    );
}
