import React, { createContext, useContext, useMemo, useReducer } from "react";

import Actions from "../Actions/Actions";
import Reducer, { initialState } from "../Reducers/Reducers";
import { LanguageResponsiveMessages } from "../Utils/LanguageResponsiveMessages";
import { ValidErrorMessages } from "../Utils/ValidErrorMessages";
import { ApiErrorMessages } from "../Utils/ApiErrorMessages";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

// 各コンポーネントでReducer、Actionsを使えるようにする
// Reducer:現在のReducerのstate
// Action:Actionsのメソッド
export default function ApplicationProvider({ children }) {
    const [storeState, dispatch] = useReducer(Reducer, initialState);
    const actions = new Actions(dispatch);
    const responsiveMessages = useMemo(() => {
        return new LanguageResponsiveMessages(storeState.language);
    }, [storeState.language]);
    const validErrorMessages = useMemo(() => {
        return new ValidErrorMessages(storeState.language);
    }, [storeState.language]);
    const apiErrorMessages = useMemo(() => {
        return new ApiErrorMessages(storeState.language);
    }, [storeState.language]);

    return (
        <AppContext.Provider
            value={{
                Reducer: storeState, Actions: actions,
                LanguageResponsiveMessages: responsiveMessages, ValidErrorMessages: validErrorMessages,
                ApiErrorMessages: apiErrorMessages
            }}>
            {children}
        </AppContext.Provider>
    );
};
