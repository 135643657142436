import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";
import ImageModel from "./ImageModel";

export default class MyArtModel {
    static DATA_TYPE_DETAIL = 'DATA_TYPE_DETAIL';
    static DATA_TYPE_MY_ART = 'DATA_TYPE_MY_ART';

    constructor(dataObject = null, dataType) {
        this.Id = 0; // int
        this.JPProductTitle = "";
        this.ENProductTitle = "";
        this.JPArtistDisplayName = "";
        this.ENArtistDisplayName = "";
        this.JPProductDescription = "";
        this.ENProductDescription = "";
        this.TotalEditions = 0;
        this.EditionNumber = 0;
        this.CurrencyUnit = "";
        this.PurchasePrice = 0;
        this.ProductImage = []; // ImageModel
        this.ProductURL = []; // URLModel

        if (dataObject !== null) {
            switch (dataType) {
                case MyArtModel.DATA_TYPE_MY_ART: {
                    this.Id = Number(dataObject.MyArtID);
                    this.JPProductTitle = dataObject.JPProductTitle;
                    this.ENProductTitle = dataObject.ENProductTitle;
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;

                    const newProductImage = new ImageModel();
                    newProductImage.ImageDisplayOrder = 1;
                    newProductImage.ImageFilepath = dataObject.ProductImageFilepath;
                    newProductImage.ImageObjectURL = dataObject.ProductImageObjectURL;
                    this.ProductImage = [newProductImage];

                    break;
                }
                case MyArtModel.DATA_TYPE_DETAIL:
                default: {
                    this.Id = Number(dataObject.MyArtID);
                    this.JPProductTitle = dataObject.JPProductTitle;
                    this.ENProductTitle = dataObject.ENProductTitle;
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;
                    this.JPProductDescription = dataObject.JPProductDescription;
                    this.ENProductDescription = dataObject.ENProductDescription;
                    this.TotalEditions = dataObject.TotalEditions;
                    this.EditionNumber = dataObject.EditionNumber;
                    this.CurrencyUnit = dataObject.CurrencyUnit;
                    this.PurchasePrice = dataObject.PurchasePrice;
                    break;
                }
            }
        }
    }

    getProductTitle(lang = JA) {
        if (lang === JA) {
            return this.JPProductTitle;
        } else {
            return this.ENProductTitle;
        }
    }

    getArtistDisplayName(lang = JA) {
        if (lang === JA) {
            return this.JPArtistDisplayName;
        } else {
            return this.ENArtistDisplayName;
        }
    }

    gatProductDescription(lang = JA) {
        if (lang === JA) {
            return this.JPProductDescription;
        } else {
            return this.ENProductDescription;
        }
    }

    update(dataObject) {
        const newMyArtModel = new MyArtModel();

        newMyArtModel.Id = this.Id;
        newMyArtModel.JPProductTitle = dataObject.JPProductTitle;
        newMyArtModel.ENProductTitle = dataObject.ENProductTitle;
        newMyArtModel.JPArtistDisplayName = dataObject.JPArtistDisplayName;
        newMyArtModel.ENArtistDisplayName = dataObject.ENArtistDisplayName;
        newMyArtModel.JPProductDescription = this.JPProductDescription;
        newMyArtModel.ENProductDescription = this.ENProductDescription;
        newMyArtModel.TotalEditions = this.TotalEditions;
        newMyArtModel.EditionNumber = this.EditionNumber;

        newMyArtModel.ProductImage = [
            ...dataObject.ProductImage,
            ...this.ProductImage.filter(x => x.ImageDisplayOrder !== 1),
        ];
        newMyArtModel.ProductURL = this.ProductURL;

        return newMyArtModel;
    }
}
