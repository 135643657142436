import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";
import ImageModel from "./ImageModel";

export default class ArtModel {
    static DATA_TYPE_DETAIL = 'DATA_TYPE_DETAIL';
    static DATA_TYPE_MY_ART = 'DATA_TYPE_MY_ART';
    static DATA_TYPE_FOLLOW = 'DATA_TYPE_FOLLOW';
    static DATA_TYPE_PICK_UP = 'DATA_TYPE_PICK_UP';

    constructor(dataObject = null, dataType) {
        this.Id = 0; // int
        this.JPProductTitle = "";
        this.ENProductTitle = "";
        this.JPArtistDisplayName = "";
        this.ENArtistDisplayName = "";
        this.JPProductDescription = "";
        this.ENProductDescription = "";
        this.TotalEditions = "";
        this.ProductImage = []; // ImageModel
        this.ProductURL = []; // URLModel

        if (dataObject !== null) {
            switch (dataType) {
                case ArtModel.DATA_TYPE_MY_ART:
                case ArtModel.DATA_TYPE_FOLLOW:
                case ArtModel.DATA_TYPE_PICK_UP: {
                    this.Id = Number(dataObject.ProductID);
                    this.JPProductTitle = dataObject.JPProductTitle;
                    this.ENProductTitle = dataObject.ENProductTitle;
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;

                    const newProductImage = new ImageModel();
                    newProductImage.ImageDisplayOrder = 1;
                    newProductImage.ImageFilepath = dataObject.ProductImageFilepath;
                    newProductImage.ImageObjectURL = dataObject.ProductImageObjectURL;
                    this.ProductImage = [newProductImage];

                    break;
                }
                case ArtModel.DATA_TYPE_DETAIL:
                default: {
                    this.Id = Number(dataObject.ProductID);
                    this.JPProductTitle = dataObject.JPProductTitle;
                    this.ENProductTitle = dataObject.ENProductTitle;
                    this.JPArtistDisplayName = dataObject.JPArtistDisplayName;
                    this.ENArtistDisplayName = dataObject.ENArtistDisplayName;
                    this.JPProductDescription = dataObject.JPProductDescription;
                    this.ENProductDescription = dataObject.ENProductDescription;
                    this.TotalEditions = dataObject.TotalEditions;
                    break;
                }
            }
        }
    }

    getProductTitle(lang = JA) {
        if (lang === JA) {
            return this.JPProductTitle;
        } else {
            return this.ENProductTitle;
        }
    }

    getArtistDisplayName(lang = JA) {
        if (lang === JA) {
            return this.JPArtistDisplayName;
        } else {
            return this.ENArtistDisplayName;
        }
    }

    gatProductDescription(lang = JA) {
        if (lang === JA) {
            return this.JPProductDescription;
        } else {
            return this.ENProductDescription;
        }
    }

    update(dataObject) {
        const newArtModel = new ArtModel();

        newArtModel.Id = this.Id;
        newArtModel.JPProductTitle = dataObject.JPProductTitle;
        newArtModel.ENProductTitle = dataObject.ENProductTitle;
        newArtModel.JPArtistDisplayName = dataObject.JPArtistDisplayName;
        newArtModel.ENArtistDisplayName = dataObject.ENArtistDisplayName;
        newArtModel.JPProductDescription = this.JPProductDescription;
        newArtModel.ENProductDescription = this.ENProductDescription;
        newArtModel.TotalEditions = this.TotalEditions;

        newArtModel.ProductImage = [
            ...dataObject.ProductImage,
            ...this.ProductImage.filter(x => x.ImageDisplayOrder !== 1),
        ];
        newArtModel.ProductURL = this.ProductURL;

        return newArtModel;
    }
}
