import { Alert, AlertTitle } from '@mui/material';
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../Contexts/AppContext";
import AppConfig from "../../Utils/AppConfig";
import Loader from '../CommonParts/Loader';

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlags = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

/**
 * カスタムルートコンポーネント
 * 前処理の結果に応じて遷移をスイッチする。
 */
export default function SigninStatusCheckRoute() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const [params] = useSearchParams();

    const [userAuthSettingFetching, setUserAuthSettingFetching] = useState(initialLoadingFlags);
    const [uesrSubFetching, setUesrSubFetching] = useState(initialLoadingFlags);
    const [isSingedIn, setIsSingedIn] = useState(false);
    const [signinStatusChecking, setSigninStatusChecking] = useState(true);
    const [isTimeout, setIsTimeout] = useState(false);

    const authCode = params.get("code");

    // システム初期表示時の処理
    useEffect(() => {
        if (authCode) {
            const postData = { code: authCode };
            userAuthSettingFetch(postData);
            navigate("/", { replace: "true" });
        } else {
            const postData = { UserID: Number(localStorage.getItem("userId")) };
            uesrSubFetch(postData);
        }
    }, [])


    // 画面遷移時のログインステータス確認（userSub取得）
    useEffect(() => {
        if (Reducer.userAuth.userId) {
            const postData = { UserID: Number(Reducer.userAuth.userId) };
            uesrSubFetch(postData);
        }
    }, [pathname])

    function userAuthSettingFetch(postData) {
        setUserAuthSettingFetching({ loading: true, loadingError: false, statusCode: 0 });
        fetch(AppConfig.UserAuthSetting, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(err => {
                            throw err
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(userAuth => {
                if (userAuth.statusCode == 200) {
                    setIsSingedIn(true);
                    setUserAuthSettingFetching(initialLoadingFlags);
                    localStorage.setItem("userId", userAuth.UserID);
                    localStorage.setItem("userSub", userAuth.userSub);
                    Actions.setUserAuth(userAuth.UserID, userAuth.userSub, userAuth.email);
                } else {
                    setIsSingedIn(false);
                    setUserAuthSettingFetching({ loading: false, loadingError: true, statusCode: userAuth.statusCode });
                }
                setSigninStatusChecking(false);
            })
            .catch(e => {
                setIsSingedIn(false);
                setSigninStatusChecking(false);
                setUserAuthSettingFetching({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
            });
    }

    function uesrSubFetch(postData) {
        setUesrSubFetching({ loading: true, loadingError: false, statusCode: 0 });
        fetch(AppConfig.GetUserSub, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(err => {
                            throw err
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(userAuth => {
                if (userAuth.statusCode == 200) {
                    setIsSingedIn(true);
                    setUesrSubFetching(initialLoadingFlags);
                    if (Reducer.userAuth.userSub !== userAuth.userSub) {
                        Actions.setUserAuth(localStorage.getItem("userId"), userAuth.user_sub, userAuth.email);
                    }
                } else {
                    setIsSingedIn(false);
                    setUesrSubFetching({ loading: false, loadingError: true, statusCode: userAuth.statusCode });
                }
                setSigninStatusChecking(false);
            })
            .catch(e => {
                setIsSingedIn(false);
                setSigninStatusChecking(false);
                setUesrSubFetching({ loading: false, loadingError: true, statusCode: Number(e.StatusCode) });
            });
    }

    const errorMessage =
        userAuthSettingFetching.loadingError || uesrSubFetching.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {ApiErrorMessages.apiMessage(userAuthSettingFetching.statusCode, "SigninStatusCheckRoute.userAuthSettingFetch")}
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (uesrSubFetching.loading) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [uesrSubFetching.loading]);

    return (
        <>
            <Loader open={userAuthSettingFetching.loading || (uesrSubFetching.loading && isTimeout)} />
            {errorMessage}
            {signinStatusChecking
                ? null
                : isSingedIn
                    ? <Outlet />
                    : null
            }
        </>
    );
}
