import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";
import ImageModel from "./ImageModel";

export default class EventModel {
    static DATA_TYPE_DETAIL = 'DATA_TYPE_DETAIL';
    static DATA_TYPE_FOLLOW = 'DATA_TYPE_FOLLOW';
    static DATA_TYPE_PICK_UP = 'DATA_TYPE_PICK_UP';

    constructor(dataObject = null, dataType) {
        this.EventID = 0;
        this.JPEventTitle = "";
        this.ENEventTitle = "";
        this.EventStartDateTime = "";
        this.EventEndDateTime = "";
        this.JPEventVenue = "";
        this.ENEventVenue = "";
        this.JPEventDescription = "";
        this.ENEventDescription = "";
        this.EventImage = null; //ImageModel
        this.EventURL = []; //URLModel

        if (dataObject !== null) {
            switch (dataType) {
                case EventModel.DATA_TYPE_FOLLOW:
                case EventModel.DATA_TYPE_PICK_UP: {
                    this.EventID = Number(dataObject.EventID);
                    this.JPEventTitle = dataObject.JPEventTitle;
                    this.ENEventTitle = dataObject.ENEventTitle;

                    this.EventImage = new ImageModel();
                    this.EventImage.ImageFilepath = dataObject.EventImageFilepath;
                    this.EventImage.ImageObjectURL = dataObject.EventImageObjectURL;

                    break;
                }
                case EventModel.DATA_TYPE_DETAIL:
                default: {
                    this.EventID = Number(dataObject.EventID);
                    this.JPEventTitle = dataObject.JPEventTitle;
                    this.ENEventTitle = dataObject.ENEventTitle;
                    this.EventStartDateTime = dataObject.EventStartDateTime;
                    this.EventEndDateTime = dataObject.EventEndDateTime;
                    this.JPEventVenue = dataObject.JPEventVenue;
                    this.ENEventVenue = dataObject.ENEventVenue;
                    this.JPEventDescription = dataObject.JPEventDescription;
                    this.ENEventDescription = dataObject.ENEventDescription;
                    break;
                }
            }
        }
    }

    getEventTitle(lang = JA) {
        if (lang === JA) {
            return this.JPEventTitle;
        } else {
            return this.ENEventTitle;
        }
    }

    getEventVenue(lang = JA) {
        if (lang === JA) {
            return this.JPEventVenue;
        } else {
            return this.ENEventVenue;
        }
    }

    getEventDescription(lang = JA) {
        if (lang === JA) {
            return this.JPEventDescription;
        } else {
            return this.ENEventDescription;
        }
    }

    update(dataObject) {
        const newArtistModel = new EventModel();

        newArtistModel.EventID = this.EventID;
        newArtistModel.JPEventTitle = dataObject.JPEventTitle;
        newArtistModel.ENEventTitle = dataObject.ENEventTitle;
        newArtistModel.EventStartDateTime = this.EventStartDateTime;
        newArtistModel.EventEndDateTime = this.EventEndDateTime;
        newArtistModel.JPEventVenue = this.JPEventVenue;
        newArtistModel.ENEventVenue = this.ENEventVenue;
        newArtistModel.JPEventDescription = this.JPEventDescription;
        newArtistModel.ENEventDescription = this.ENEventDescription;
        newArtistModel.EventImage = dataObject.EventImage;
        newArtistModel.EventURL = this.EventURL;

        return newArtistModel;
    }
}
