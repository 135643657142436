import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";
import { Link } from '@mui/material';

export class ApiErrorMessages {
    constructor(lang) {
        this.Language = lang;
    }

    apiMessage(statusCode, type) {
        if (statusCode == 500) {
            if (this.Language == JA) {
                return "認証に失敗しました。再度ログインしてください。";
            } else {
                return "Authentication failed. Please login again.";
            }
        }

        switch (type) {
            case "Reducer.artistInfoFetch":
                if (this.Language == JA) {
                    return "アーティスト詳細を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Artist Deatails. Please reload the screen.";

            case "Reducer.relatedArtListFetch":
            case "Reducer.exhibitArtListFetch":
                if (this.Language == JA) {
                    return "アートを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Arts. Please reload the screen.";

            case "Reducer.exhibitEventListFetch":
                if (this.Language == JA) {
                    return "イベントを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Events. Please reload the screen.";

            case "Reducer.insertFollowContents":
                if (this.Language == JA) {
                    return "フォロー登録に失敗しました。時間をおいて再度お試しください。";
                }
                return "Follow registration failed. Please try again after some time..";

            case "Reducer.deleteFollowContents":
                if (this.Language == JA) {
                    return "フォロー解除に失敗しました。時間をおいて再度お試しください。";
                }
                return "Failed to unfollow. Please try again after some time..";

            case "ArtistDetail.fetchFollowStatus":
            case "EventDetail.fetchFollowStatus":
            case "McaDetail.fetchFollowStatus":
                if (this.Language == JA) {
                    return "フォローステータスを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Follow Status. Please reload the screen.";

            case "EditEmail.onSendEmail":
                if (statusCode == 401) {
                    if (this.Language == JA) {
                        return "メールアドレスが不正です。正しく入力してください。";
                    }
                    return "Invalid email address. Please enter the correct value.";
                }
                if (this.Language == JA) {
                    return "メール送信に失敗しました。時間をおいて再度お試しください。";
                }
                return "Failed to send email. Please try again after some time..";

            case "EditEmailCheckAuthcode.onSendAuthcode":
                if (statusCode == 501) {
                    if (this.Language == JA) {
                        return "確認コードが不正です。正しく入力してください。";
                    }
                    return "Invalid verification code. Please enter the correct value.";
                }
                if (this.Language == JA) {
                    return "確認コードの確認に失敗しました。時間をおいて再度お試しください。";
                }
                return "Authentication code verification failed. Please try again after some time..";

            case "EditEmailCheckAuthcode.updateEmailAddress":
                if (this.Language == JA) {
                    return "メールアドレスの再設定に失敗しました。時間をおいて再度お試しください。";
                }
                return "Email address change failed. Please try again after some time..";

            case "EditPassword.onSendPassword":
                if (this.Language == JA) {
                    return "パスワードの再設定に失敗しました。時間をおいて再度お試しください。";
                }
                return "Password change failed. Please try again after some time..";

            case "Reducer.eventInfoFetch":
                if (this.Language == JA) {
                    return "イベント詳細を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Event Deatails. Please reload the screen.";

            case "Reducer.exhibitArtistListFetch":
                if (this.Language == JA) {
                    return "アーティストを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Artists. Please reload the screen.";

            case "Reducer.followContentsListFetch":
                if (this.Language == JA) {
                    return "フォロー一覧を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Follow List. Please reload the screen.";

            case "Reducer.artInfoFetch":
                if (this.Language == JA) {
                    return "アート詳細を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Art Deatails. Please reload the screen.";

            case "Reducer.myArtInfoFetch":
                if (this.Language == JA) {
                    return "保有アート詳細を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain My Art Deatails. Please reload the screen.";

            case "Reducer.goodsListFetch":
                if (this.Language == JA) {
                    return "グッズを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Goods. Please reload the screen.";

            case "Reducer.userPrivilegeInfoFetch":
                if (this.Language == JA) {
                    return "ユーザ権利情報を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain User Privilege Information. Please reload the screen.";

            case "Reducer.privilegePermissionHistoryFetch":
                if (this.Language == JA) {
                    return "権利許諾履歴を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Privilege Permission History. Please reload the screen.";

            case "Reducer.myArtListFetch":
                if (this.Language == JA) {
                    return "保有アート一覧を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain My Art List. Please reload the screen.";

            case "Reducer.searchResultListFetch":
                if (this.Language == JA) {
                    return "検索結果一覧を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Search Result List. Please reload the screen.";

            case "SigninStatusCheckRoute.userAuthSettingFetch":
                const login_url = "https://auth.dev-mypage.gaaat.com/oauth2/authorize?client_id=6nsvgt16v7291figjssilhrc37&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https%3A%2F%2Fdev-mypage.gaaat.com";
                if (this.Language == JA) {
                    return <>
                        ログインステータスが無効です。<Link href={login_url} color="inherit">再ログイン</Link>してください。
                    </>
                }
                return <>
                    Invalid login status. Please <Link href={login_url} color="inherit">login</Link> again.
                </>

            case "Reducer.pickUpContentsListFetch":
                if (this.Language == JA) {
                    return "おすすめ一覧を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain Pick Up. Please reload the screen.";

            case "Reducer.newsListFetch":
                if (this.Language == JA) {
                    return "ニュースを取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain News. Please reload the screen.";

            case "Reducer.userInfoFetch":
                if (this.Language == JA) {
                    return "アカウント情報を取得できませんでした。画面を再読み込みしてください。";
                }
                return "Could not obtain User Information. Please reload the screen.";

            case "UserInformationEdit.onSave":
                if (this.Language == JA) {
                    return "アカウント情報の変更に失敗しました。時間をおいて再度お試しください。";
                }
                return "User Information change failed. Please try again after some time..";

            case "Reducer.countoryCodeListFetch":
            case "Reducer.prefectureCodeListFetch":
                if (this.Language == JA) {
                    return "通信中にエラーが発生しました。画面を再読み込みしてください。";
                }
                return "An error occurred during communication. Please reload the screen.";

            default:
                if (this.Language == JA) {
                    return "原因不明のエラーが発生しました。再ログインしてください。";
                }
                return "An unexplained error has occurred. Please login again.";
        }
    }
}
