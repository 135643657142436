import { Alert, AlertTitle, Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAppContext } from "../../Contexts/AppContext";
import GeneralCode from "../../Model/GeneralCode";
import { Functions } from '../../Utils/Functions';
import { ButtonToOtherPage } from '../CommonParts/CommonButton';
import LabelValuePair from '../CommonParts/LabelValuePair';
import Loader from '../CommonParts/Loader';

export default function UserInformation() {
    const { Reducer, Actions, LanguageResponsiveMessages, ApiErrorMessages } = useAppContext();
    useEffect(() => {
        if (Reducer.countryCodeList.length === 0) {
            Actions.countoryCodeListFetch();
        }
        if (Reducer.prefectureCodeList.length === 0) {
            Actions.prefectureCodeListFetch();
        }
        Actions.userInfoFetch({ UserID: Number(Reducer.userAuth.userId) });
        return () => {
            Actions.userInfoFetching(false, false);
            Actions.countoryCodeListFetching(false, false);
            Actions.prefectureCodeListFetching(false, false);
        }
    }, []);

    const countryObj = new GeneralCode(Reducer.countryCodeList.find((item) => item.Code === Reducer.userInfo.CountryCode));
    const prefectureObj = new GeneralCode(Reducer.prefectureCodeList.find((item) => item.Code === Reducer.userInfo.ProvinceCode));

    const loadingFlagKeys = ["userInfoFetch", "countoryCodeListFetch", "prefectureCodeListFetch"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
            </Alert>
            : null;

    const label = LanguageResponsiveMessages.userInformationLabel;
    const commonButtonLabel = LanguageResponsiveMessages.commonButtonLabel;
    const loginInfoLabel = LanguageResponsiveMessages.loginInfo;

    return (
        <Container maxWidth="lg">
            <Loader open={loadingFlag} />
            {errorMessage}
            <Box component="div" className='titleStyle'>
                <Typography variant="h3" mb={2}>
                    User Information
                </Typography>
                <Paper>
                    <Grid container justifyContent="center" direction='column' sx={{ padding: 5 }}>
                        <LabelValuePair label={loginInfoLabel.loginId} value={Reducer.userInfo.Email} />
                        <LabelValuePair label={loginInfoLabel.password} value="******" />
                        {/* {Reducer.userInfo.BirthDate ?
                            <LabelValuePair label={label.birthDate} value={Functions.formatDateBySlash(Reducer.userInfo.BirthDate, Reducer.language)} />
                            : <LabelValuePair label={label.birthDate} />
                        } */}
                        <LabelValuePair label={label.country} value={countryObj.JPCodeDescription} />
                        <LabelValuePair label={label.fullName} value={Reducer.userInfo.FirstName + Reducer.userInfo.LastName} />
                        <LabelValuePair label={label.phoneNumber} value={Reducer.userInfo.PhoneNumber} />
                        <LabelValuePair label={label.zipCode} value={Reducer.userInfo.ZipCode} />
                        <LabelValuePair label={label.prefecture} value={prefectureObj.JPCodeDescription} />
                        <LabelValuePair label={label.city} value={Reducer.userInfo.City} />
                        <LabelValuePair label={label.cityBlock} value={Reducer.userInfo.AddressLine1} />
                        <LabelValuePair label={label.buildingName} value={Reducer.userInfo.AddressLine2} />
                        <Grid container justifyContent="center" sx={{ paddingTop: 3 }}>
                            <ButtonToOtherPage label={commonButtonLabel.edit} to="/account/userInfo/edit" />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}
