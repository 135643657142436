import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import { ButtonToOtherPage } from '../CommonParts/CommonButton';
import { useAppContext } from "../../Contexts/AppContext";

export default function EditComplete() {
    const { LanguageResponsiveMessages } = useAppContext();
    const location = useLocation();
    const { message } = location.state;

    return (
        <>
            <Container maxWidth="lg" >
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Change Complete
                    </Typography>
                    <Paper sx={{ py: 6, px: 2 }} >
                        <Box component="form" sx={{ mx: { xs: 1, sm: 4 } }} >
                            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                                <Grid xs={12} textAlign="center" sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <CheckCircleIcon fontSize="body1" sx={{ transform: 'translate(0px, 2.5px)', mr: 0.5 }} />
                                    <Typography variant="body1" component="p" sx={{ mb: 5 }} >
                                        {message}{LanguageResponsiveMessages.finishReconfiguration}
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <ButtonToOtherPage label={LanguageResponsiveMessages.backToAccountInfo} to="/account/userInfo" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box >
            </Container >
        </>
    );
}
