// import { Functions } from '../Utils/Functions';
import UserInfoModel from "./UserInfoModel";

export default class UserInfoEditModel {
    constructor() {
        this.UserID = 0;
        // this.BirthDate = "";
        this.FirstName = "";
        this.LastName = "";
        this.AddressLine1 = "";
        this.AddressLine2 = "";
        this.City = "";
        this.ProvinceCode = "";
        this.CountryCode = "";
        this.FirstZipCode = "";
        this.LastZipCode = "";
        this.PhoneNumber = "";
    }

    clone() {
        const newObject = new UserInfoEditModel();

        newObject.UserID = this.UserID;
        // newObject.BirthDate = this.BirthDate;
        newObject.FirstName = this.FirstName;
        newObject.LastName = this.LastName;
        newObject.AddressLine1 = this.AddressLine1;
        newObject.AddressLine2 = this.AddressLine2;
        newObject.City = this.City;
        newObject.ProvinceCode = this.ProvinceCode;
        newObject.CountryCode = this.CountryCode;
        newObject.FirstZipCode = this.FirstZipCode;
        newObject.LastZipCode = this.LastZipCode;
        newObject.PhoneNumber = this.PhoneNumber;

        return newObject;
    }

    validate() {
        const errors = {};
        if (this.FirstName == "" || this.FirstName == null) {
            errors.FirstName = {
                type: "requiredValue",
                jaWords: ["名"],
                enWords: ["First name"],
            }
        }

        if (this.LastName == "" || this.LastName == null) {
            errors.LastName = {
                type: "requiredValue",
                jaWords: ["姓"],
                enWords: ["Last name"],
            }
        }

        if ((this.FirstZipCode == "" || this.FirstZipCode == null)
            ^ (this.LastZipCode == "" || this.LastZipCode == null)) {
            (this.FirstZipCode == "" || this.FirstZipCode == null)
                ? errors.FirstZipCode = {
                    type: "notEntered",
                    jaWords: [],
                    enWords: [],
                }
                : errors.LastZipCode = {
                    type: "notEntered",
                    jaWords: [],
                    enWords: [],
                }
        } else if (
            !((this.FirstZipCode == "" || this.FirstZipCode == null)
                && (this.LastZipCode == "" || this.LastZipCode == null))
        ) {
            // 郵便番号 日本
            if (this.CountryCode == "JPN") {
                if (!(/^\d{3}$/.test(this.FirstZipCode))) {
                    errors.FirstZipCode = {
                        type: "digitError",
                        jaWords: ["3"],
                        enWords: ["3"],
                    }
                }

                if (!(/^\d{4}$/.test(this.LastZipCode))) {
                    errors.LastZipCode = {
                        type: "digitError",
                        jaWords: ["4"],
                        enWords: ["4"],
                    }
                }
            }
        }

        if (!(this.LastName == "" || this.LastName == null)) {
            //アルファベットと日本語の文字のみを許可
            const namePattern = /^[a-zA-Z\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+$/;
            if (!namePattern.test(this.LastName)) {
                errors.LastName = {
                    type: "invalidError",
                    jaWords: [this.LastName, "姓"],
                    enWords: [this.LastName, "Last Name"],
                }
            }
        }

        if (!(this.FirstName == "" || this.FirstName == null)) {
            //アルファベットと日本語の文字のみを許可
            const namePattern = /^[a-zA-Z\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+$/;
            if (!namePattern.test(this.FirstName)) {
                errors.FirstName = {
                    type: "invalidError",
                    jaWords: [this.FirstName, "名"],
                    enWords: [this.FirstName, "First Name"],
                }
            }
        }

        // if (this.BirthDate == "" || this.BirthDate == null) {
        //     errors.BirthDate = {
        //         type: "invalidValue",
        //         jaWords: ["生年月日", "入力"],
        //         enWords: ["date of birth", "input"],
        //     }
        // }

        return errors;
    }

    toSaveData() {
        return {
            UserID: Number(this.UserID),
            FirstName: this.FirstName.trim(),
            LastName: this.LastName.trim(),
            // BirthDate: Functions.formatDateBySlash(this.BirthDate),
            AddressLine1: this.AddressLine1 ? this.AddressLine1.trim() : "",
            AddressLine2: this.AddressLine2 ? this.AddressLine2.trim() : "",
            City: this.City ? this.City.trim() : "",
            ProvinceCode: this.ProvinceCode,
            CountryCode: this.CountryCode,
            ZipCode: this.FirstZipCode && this.LastZipCode ? this.FirstZipCode.trim() + '-' + this.LastZipCode.trim() : "",
            PhoneNumber: this.PhoneNumber ? this.PhoneNumber.trim() : "",
        };
    }

    /**
     * UserInfoModel型の値をUserInfoEditModel型に変換する。
     * @param {UserInfoModel} userInfo
     * @returns {UserInfoEditModel} UserInfoEditModel型のクラス
     */
    static parse(userInfo) {
        const newObject = new UserInfoEditModel();

        newObject.UserID = userInfo.UserID;
        // newObject.BirthDate = userInfo.BirthDate;
        newObject.FirstName = userInfo.FirstName;
        newObject.LastName = userInfo.LastName;
        newObject.AddressLine1 = userInfo.AddressLine1;
        newObject.AddressLine2 = userInfo.AddressLine2;
        newObject.City = userInfo.City;
        newObject.ProvinceCode = userInfo.ProvinceCode;
        newObject.CountryCode = userInfo.CountryCode;
        if (userInfo.ZipCode) {
            const zipCode = userInfo.ZipCode.split("-");
            newObject.FirstZipCode = zipCode[0];
            newObject.LastZipCode = zipCode[1];
        }
        newObject.PhoneNumber = userInfo.PhoneNumber;

        return newObject;
    }
}
