import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import ApplicationProvider from "./Contexts/AppContext";
import App from './App';

// Robotoフォントインポート
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApplicationProvider>
    <App />
  </ApplicationProvider>
);
