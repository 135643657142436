import Grid from '@mui/material/Unstable_Grid2';
import ImageCard from '../../CommonParts/ImageCard';

export default function GridDisplay({ mcaList }) {
    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 9, md: 16 }}>
            {mcaList.map(item => (
                <Grid key={item.id} xs={2} sm={3} md={4} display="flex" justifyContent="center" alignItems="flex-start">
                    <ImageCard imgCardObj={item} />
                </Grid>
            ))}
        </Grid>
    );
}
