import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ImageCard from './ImageCard';

export default function ImageScroller({ imgCardList }) {
    function prevArrow() {
        return <IconButton>
            <ArrowBackIosNewIcon />
        </IconButton>
    }

    function nextArrow() {
        return <IconButton>
            <ArrowForwardIosIcon />
        </IconButton>
    }

    const settings = {
        dots: false,
        swipeToSlide: true,
        slidesToShow: 4,
        swipe: imgCardList.length < 5 ? false : true,
        draggable: false,
        infinite: false,
        speed: 500,
        arrows: imgCardList.length < 5 ? false : true,
        initialSlide: 0,
        prevArrow: prevArrow(),
        nextArrow: nextArrow(),
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    swipe: imgCardList.length < 4 ? false : true,
                    arrows: imgCardList.length < 4 ? false : true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    swipe: imgCardList.length < 3 ? false : true,
                    arrows: imgCardList.length < 3 ? false : true,
                }
            },
        ]
    };

    return (
        <Slider {...settings}>
            {imgCardList.slice(0, 10).map(item => (
                <ImageCard imgCardObj={item} />
            ))}
        </Slider>
    );
}

