import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/X';
import { Box, Container, IconButton, Link, List, ListItem, Typography } from '@mui/material';
import { useAppContext } from "../../Contexts/AppContext";
import GaaatLog from './GaaatLog';

export default function Footer() {
    const { LanguageResponsiveMessages } = useAppContext();

    const label = LanguageResponsiveMessages.footerLabel;
    const linkList = [
        { id: 0, text: label.conditionsOfUse, url: '' },
        { id: 1, text: label.privacyPolicy, url: 'https://gaaat.com/policy' },
    ];

    return <Box component="footer" py={5} sx={{ position: 'sticky', top: '100vh' }}>
        <Container>
            <Box component="div"
                sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'flex-end' }, justifyContent: 'space-between' }}>
                <Box className='footer_li_left'>
                    <GaaatLog size='13rem' />
                    <div>
                        <Typography variant="body1" component="p">
                            ©Copyright,
                            <Link href="#" color="inherit" sx={{ mx: 1 }}>
                                GAAAT.com,
                            </Link>
                            2024
                        </Typography>
                    </div>
                    <div>
                        <IconButton target="_blank" href="https://twitter.com/GAAAT_ART">
                            <TwitterIcon />
                        </IconButton>
                        <IconButton target="_blank" href="https://www.instagram.com/gaaat_art/">
                            <InstagramIcon />
                        </IconButton>
                    </div>
                </Box>
                <List className='footer_li_right'>
                    {linkList.map(item => {
                        return (
                            <ListItem key={item.id} sx={{ p: 0 }}>
                                <Link target="_blank" href={item.url} variant=" overline" lineHeight="normal" color="inherit" sx={{ textTransform: 'none' }}>
                                    {item.text}
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Container >
    </Box >
}
