export default class URLModel {
    constructor(dataObject = null) {
        this.URLTypeName = "";
        this.URLDisplayText = "";
        this.URL = "";

        if (dataObject !== null) {
            this.URLTypeName = dataObject.URLTypeName;
            this.URLDisplayText = dataObject.URLDisplayText;
            this.URL = dataObject.URL;
        }
    }
}
