import { Link as RouterLink } from "react-router-dom";

export default function GaaatLog({ size }) {
    return (
        <RouterLink to="/">
            <img
                style={{ width: size }}
                src={`${process.env.PUBLIC_URL}/gaaat_log.webp`}
                alt='gaaat_log' />
        </RouterLink>
    );
}
