import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants';
import { useAppContext } from '../../../Contexts/AppContext';
import { ButtonForFavorite, ButtonToExternalSites } from '../../CommonParts/CommonButton';
import { Functions } from '../../../Utils/Functions';

function createInitialDisplayImg(imgDataList) {
    if (!Functions.isEmpty(imgDataList)) {
        return imgDataList[0].ImageObjectURL;
    }
    return Constants.NO_IMAGE_URL;
}

export default function PurchasedMcaDetailedInformation({ mca, imgDataList, title, artist, orderPlaced, info, size, manufacturingDate, technique, totalEditions, editionNumber, pictureFraming, signature, productHref, currencyUnit, purchasePrice }) {
    const { Reducer, Actions, LanguageResponsiveMessages } = useAppContext();
    const [displayImg, setDisplayImg] = useState(() => createInitialDisplayImg(imgDataList));
    const { id } = useParams();

    useEffect(() => {
        setDisplayImg(createInitialDisplayImg(imgDataList));
    }, [imgDataList]);

    function onImgClick(evn) {
        const displayImg = imgDataList.find(x => x.ImageDisplayOrder == evn.target.id);
        setDisplayImg(displayImg.ImageObjectURL);
    }

    function onFollowClick() {
        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            ContentsType: Constants.FOLLOW_CONTENTS_TYPE_PRODUCT,
            ContentsID: Number(id),
        };
        Actions.insertFollowContentsFetch(postData);
    }

    function onUnfollowClick() {
        const postData = {
            UserID: Number(Reducer.userAuth.userId),
            ContentsType: Constants.FOLLOW_CONTENTS_TYPE_PRODUCT,
            ContentsID: Number(id),
        };
        Actions.deleteFollowContentsFetch(postData);
    }

    function handleImageError(e) {
        e.target.src = Constants.NO_IMAGE_URL;
    }

    const followStatus = Reducer.followContentsList.some(x => x.Id == id && x.IsArt);

    const columnImgList =
        <Stack direction="column" spacing={2} sx={{ overflow: 'auto', maxHeight: 500, '&::-webkit-scrollbar': { height: 10 } }}>
            {imgDataList.map(imgData => (
                <img style={{ borderRadius: 4, width: '100%' }}
                    id={imgData.ImageDisplayOrder}
                    src={imgData.ImageObjectURL}
                    alt={imgData.ImageFilepath}
                    onClick={onImgClick}
                    onError={handleImageError}
                />
            ))}
        </Stack>

    const rowImgList =
        <Stack direction="row" spacing={2} sx={{ overflow: 'auto', '&::-webkit-scrollbar': { height: '1' } }}>
            {imgDataList.map(imgData => (
                <img width='100' style={{ borderRadius: 4 }}
                    id={imgData.ImageDisplayOrder}
                    src={imgData.ImageObjectURL}
                    alt={imgData.ImageFilepath}
                    onClick={onImgClick}
                    onError={handleImageError}
                />
            ))}
        </Stack>

    const artInfoLabel = LanguageResponsiveMessages.artInfoLabel;

    return <>
        <Grid container spacing={5}>
            <Grid item container md={7} columns={14} spacing={2} sx={{ alignItems: { xs: 'none', md: 'center' } }}>
                <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                    {columnImgList}
                </Grid>
                <Grid item md={11}>
                    <img src={displayImg} alt={displayImg} style={{ borderRadius: 4, width: '100%' }} onError={handleImageError} />
                </Grid>
                <Grid item sx={{ display: { xs: 'block', md: 'none' }, maxWidth: '100%' }}>
                    {rowImgList}
                </Grid>
            </Grid>
            <Grid item container md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start" }}>
                {mca ?
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonToExternalSites href={productHref?.URL} label={artInfoLabel.productPage} margin='0 10px 0 0' />
                        <ButtonForFavorite onFollowClick={onFollowClick} followStatus={followStatus} onUnfollowClick={onUnfollowClick} />
                    </div>
                    : ""
                }
                <div>
                    <Typography variant="subtitle2" sx={{ color: "#a9a9a9" }}>Title</Typography>
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="subtitle2" className='detailInformationTitleStyle'>Artist</Typography>
                    <Typography variant="body1">{artist}</Typography>
                    {orderPlaced ?
                        <Typography variant="subtitle2" className='detailInformationTitleStyle'>Order placed</Typography>
                        : ""}
                    <Typography variant="body1">{orderPlaced}</Typography>
                    <Typography variant="subtitle2" className='detailInformationTitleStyle'>Information</Typography>
                    <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", mb: 2 }}>{Functions.isEmpty(info) ? "" : info.replace('。', '。\n')}</Typography>
                    {size ?
                        <Typography variant="body1" >{artInfoLabel.sizeLabel} : {size}</Typography>
                        : ""}
                    {manufacturingDate ?
                        <Typography variant="body1" >{artInfoLabel.manufacturingDateLabel} : {manufacturingDate}</Typography>
                        : ""}
                    {technique ?
                        <Typography variant="body1" >{artInfoLabel.techniqueLabel} : {technique}</Typography>
                        : ""
                    }
                    {totalEditions ?
                        <Typography variant="body1" >{artInfoLabel.totalEditionsLabel} : {totalEditions}</Typography>
                        : ""
                    }
                    {editionNumber ?
                        <Typography variant="body1" >{artInfoLabel.editionNumber} : {editionNumber}</Typography>
                        : ""
                    }
                    {pictureFraming ?
                        <Typography variant="body1" >{artInfoLabel.pictureFramingLabel} : {pictureFraming}</Typography>
                        : ""
                    }
                    {signature ?
                        <Typography variant="body1" >{artInfoLabel.signatureLabel} : {signature}</Typography>
                        : ""
                    }
                    {purchasePrice ?
                        <Typography variant="body1" >{artInfoLabel.purchasePrice} : {purchasePrice.toLocaleString()} {currencyUnit}</Typography>
                        : ""
                    }
                </div>
            </Grid>
        </Grid >
    </>
}
