import { Box, Typography } from '@mui/material';

export default function LabelValuePair({ label, value }) {
    return <Box sx={{ mb: 1 }}>
        <Typography variant='subtitle1' color='text.secondary' className='account-typography'>
            {label}
        </Typography>
        <Typography variant='body1' className='account-typography'>
            {value}
        </Typography>
    </Box>
}
