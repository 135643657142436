import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";

export default class UserPrivilegeModel {
    constructor(dataObject = null) {
        this.Id = 0; // int
        this.JPPrivilegeDetail = "";
        this.ENPrivilegeDetail = "";
        this.JPContentsName = "";
        this.ENContentsName = "";
        this.JPPrivilegeDescription = "";
        this.ENPrivilegeDescription = "";
        this.PrivilegeStartDate = "";
        this.PrivilegeEndDate = "";
        this.PJArtistPermissionStatus = "";
        this.ENArtistPermissionStatus = "";
        this.ArtistPermissionReason = "";
        this.JPRaptorsPermissionStatus = "";
        this.ENRaptorsPermissionStatus = "";
        this.RaptorsPermissionReason = "";

        if (dataObject !== null) {
            this.Id = Number(dataObject.UserPrivilegeID);
            this.JPPrivilegeDetail = dataObject.JPPrivilegeDetail;
            this.ENPrivilegeDetail = dataObject.ENPrivilegeDetail;
            this.JPContentsName = dataObject.JPContentsName;
            this.ENContentsName = dataObject.ENContentsName;
            this.JPPrivilegeDescription = dataObject.JPPrivilegeDescription;
            this.ENPrivilegeDescription = dataObject.ENPrivilegeDescription;
            this.PrivilegeStartDate = dataObject.PrivilegeStartDate;
            this.PrivilegeEndDate = dataObject.PrivilegeEndDate;
            this.JPArtistPermissionStatus = dataObject.JPArtistPermissionStatus;
            this.ENArtistPermissionStatus = dataObject.ENArtistPermissionStatus;
            this.ArtistPermissionReason = dataObject.ArtistPermissionReason;
            this.JPRaptorsPermissionStatus = dataObject.JPRaptorsPermissionStatus;
            this.ENRaptorsPermissionStatus = dataObject.ENRaptorsPermissionStatus;
            this.RaptorsPermissionReason = dataObject.RaptorsPermissionReason;
        }
    }

    getPrivilegeDetail(lang = JA) {
        if (lang === JA) {
            return this.JPPrivilegeDetail;
        } else {
            return this.ENPrivilegeDetail;
        }
    }

    getContentsName(lang = JA) {
        if (lang === JA) {
            return this.JPContentsName;
        } else {
            return this.ENContentsName;
        }
    }

    getPrivilegeDescription(lang = JA) {
        if (lang === JA) {
            return this.JPPrivilegeDescription;
        } else {
            return this.ENPrivilegeDescription;
        }
    }

    getArtistPermissionStatus(lang = JA) {
        if (lang === JA) {
            return this.JPArtistPermissionStatus;
        } else {
            return this.ENArtistPermissionStatus;
        }
    }

    getRaptorsPermissionStatus(lang = JA) {
        if (lang === JA) {
            return this.JPRaptorsPermissionStatus;
        } else {
            return this.ENRaptorsPermissionStatus;
        }
    }
}
