// API通信用
const BaseApiUrl = process.env.REACT_APP_API_URL;
const BaseAuthApiUrl = process.env.REACT_APP_AUTH_API_URL;

export default class AppConfig {
    static UserAuthSetting = BaseAuthApiUrl + "user-auth-setting";
    static GetUserSub = BaseAuthApiUrl + "getCognitoUsersub";
    static ChangeEmailAddress = BaseAuthApiUrl + "changeEmailAddress";
    static CheckEmailAddress = BaseAuthApiUrl + "checkEmailAddress";
    static UpdatePassword = BaseAuthApiUrl + "updateCognitoPassword";
    static UpdateEmailAddress = BaseAuthApiUrl + "updateEmailAddress";

    static GetUserInfo = BaseApiUrl + "get-user-info";
    static UpdateUserInfo = BaseApiUrl + "update-user-info";
    static GetMyArtList = BaseApiUrl + "get-my-art-list";
    static GetFollowContentsList = BaseApiUrl + "get-follow-contents-list";
    static GetPickUpContentsList = BaseApiUrl + "get-pick-up-contents-list";
    static GetNewsList = BaseApiUrl + "get-news-list";
    static GetArtistInfo = BaseApiUrl + "get-artist-info";
    static GetExhibitEventList = BaseApiUrl + "get-exhibit-event-list";
    static GetRelatedArtList = BaseApiUrl + "get-related-art-list";
    static GetFollowStatus = BaseApiUrl + "get-follow-status";
    static GetEventInfo = BaseApiUrl + "get-event-info";
    static GetExhibitArtList = BaseApiUrl + "get-exhibit-art-list";
    static GetExhibitArtistList = BaseApiUrl + "get-exhibit-artist-list";
    static GetArtInfo = BaseApiUrl + "get-art-info";
    static GetMyArtInfo = BaseApiUrl + "get-my-art-info";
    static GetUserPrivilegeInfo = BaseApiUrl + "get-user-privilege-info";
    static GetPrivilegePermissionHistory = BaseApiUrl + "get-privilege-permission-history";
    static GetGoodsList = BaseApiUrl + "get-goods-list";
    static GetSearchResultList = BaseApiUrl + "get-search-result-list";
    static InsertFollowContents = BaseApiUrl + "insert-follow-contents";
    static DeleteFollowContents = BaseApiUrl + "delete-follow-contents";
    static GetCodeList = BaseApiUrl + "get-code-list";
}
