import { LANGUAGE_CODE_TYPE_JAPANESE as JA } from "../Constants/Constants";

export default class NewsModel {
    constructor(dataObject = null) {
        this.Id = 0; // int
        this.JPNewsTitle = "";
        this.ENNewsTitle = "";
        this.JPNewsDescription = "";
        this.ENNewsDescription = "";
        this.PublicationDateTime = ""
        this.NewsURL = []; // NewsURLModel

        if (dataObject !== null) {
            this.Id = Number(dataObject.NewsID);
            this.JPNewsTitle = dataObject.JPNewsTitle;
            this.ENNewsTitle = dataObject.ENNewsTitle;
            this.JPNewsDescription = dataObject.JPNewsDescription;
            this.ENNewsDescription = dataObject.ENNewsDescription;
            this.PublicationDateTime = dataObject.PublicationDateTime;
        }
    }

    getNewsTitle(lang = JA) {
        if (lang === JA) {
            return this.JPNewsTitle;
        } else {
            return this.ENNewsTitle;
        }
    }

    getNewsDescription(lang = JA) {
        if (lang === JA) {
            return this.JPNewsDescription;
        } else {
            return this.ENNewsDescription;
        }
    }
}
