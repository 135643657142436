import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { AppBar, Box, Fade, FormControl, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Toolbar } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from "../../Contexts/AppContext";
import GaaatLog from './GaaatLog';

export default function Header() {
    const navigate = useNavigate(); // 画面遷移
    const location = useLocation();
    const { LanguageResponsiveMessages } = useAppContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const anchorEl = useRef(null);
    const [searchBarOpen, setSearchBarOpen] = useState(false); // 検索バー表示フラグ
    const [searchValue, setSearchValue] = useState("");

    // ページが切り替わるたびに検索バーを閉じる
    useEffect(() => {
        if (location.pathname !== "/search") {
            searchBarOpenClose(false);
        }
    }, [location]);

    function onSearchClick() {
        searchBarOpenClose(!searchBarOpen);
    };

    function searchBarOpenClose(open) {
        setSearchBarOpen(open);
        onSearchValueClear();
    };

    function onMenuClick() {
        setMenuOpen(!menuOpen);
    };

    function onMenuClose(event) {
        setMenuOpen(false);
    }

    function onMenuItemSelect(event, value, text) {
        if (text === label.signOut) {
            localStorage.clear();
            window.open(value, '_self');
        } else {
            navigate(value);
        }
        setMenuOpen(false);
    }

    function onSearchValueChange(event) {
        setSearchValue(event.target.value);
    }

    function onSearchValueClear() {
        setSearchValue("");
    }

    function onSubmit(evn) {
        evn.preventDefault();
        if (searchValue) {
            navigate(`/search?q=${searchValue}`);
        }
    }

    const signoutUrl = "https://auth.dev-mypage.gaaat.com/logout?client_id=6nsvgt16v7291figjssilhrc37&logout_uri=https%3A%2F%2Fgaaat.com";
    const label = LanguageResponsiveMessages.headerLabel;
    const menuOptions = [
        { key: 1, value: "/account/userInfo", text: label.userInformation },
        { key: 2, value: "/account/purchasedMca", text: label.myArtList },
        { key: 3, value: "/account/favorites", text: label.followList },
        { key: 4, value: signoutUrl, text: label.signOut },
    ];

    return <>
        <AppBar position="fixed">
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, mr: 2 }} >
                    <GaaatLog size='10rem' />
                </Box>
                <Fade in={!searchBarOpen}
                    style={{ transitionDelay: !searchBarOpen ? '200ms' : '-100ms', }}
                    unmountOnExit
                >
                    <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' }, mr: 2 }} >
                        <GaaatLog size='10rem' />
                    </Box>
                </Fade>
                <Fade in={searchBarOpen}
                    style={{ transitionDelay: searchBarOpen ? '200ms' : '-200ms' }}
                >
                    <form onSubmit={onSubmit} style={{ width: '100%' }}>
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" >
                            <OutlinedInput
                                id="outlined-adornment-password"
                                sx={{ width: '100%' }}
                                type="text"
                                value={searchValue}
                                onChange={onSearchValueChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onSearchValueClear}
                                            edge="end"
                                        >
                                            {searchValue ? <ClearIcon /> : null}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder={label.searchPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                        </FormControl>
                    </form>
                </Fade>
                <IconButton color="inherit"
                    sx={{ ml: 2 }}
                    aria-controls={searchBarOpen ? 'searchBar' : undefined}
                    aria-expanded={searchBarOpen.toString()}
                    onClick={onSearchClick}
                >
                    {searchBarOpen ? <SearchOffIcon /> : <SearchIcon />}
                </IconButton>
                <IconButton color="inherit"
                    aria-controls={menuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen.toString()}
                    ref={anchorEl}
                    onClick={onMenuClick}
                >
                    <MenuIcon />
                </IconButton>
                <Menu id="basic-menu"
                    anchorEl={anchorEl.current}
                    open={menuOpen}
                    onClose={onMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {menuOptions.map(item => {
                        return (
                            <MenuItem key={item.key}
                                value={item.value}
                                onClick={(event) => onMenuItemSelect(event, item.value, item.text)}
                            >
                                {item.text}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Toolbar>
        </AppBar >
        <Toolbar />
    </>
}
