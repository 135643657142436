import { Alert, AlertTitle, Box, Container, Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Constants from '../../Constants/Constants';
import { useAppContext } from "../../Contexts/AppContext";
import DetailInformation from './Parts/DetailInformation';
import ImageScroller from '../CommonParts/ImageScroller';
import Loader from "../CommonParts/Loader";
import AppConfig from "../../Utils/AppConfig";

const apiKey = process.env.REACT_APP_API_KEY;

const initialLoadingFlag = {
    loading: false,
    loadingError: false,
    statusCode: 0,
};

export default function ArtistDetail() {
    const { Reducer, Actions, ApiErrorMessages } = useAppContext();
    const { id } = useParams();
    const [followStatusFetch, setFollowStatusFetch] = useState(initialLoadingFlag);
    const [isTimeout, setIsTimeout] = useState(false);

    const artistInfo = useMemo(() => {
        return Reducer.artistInfoList.find(x => x.Id == id);
    }, [Reducer.artistInfoList, id])

    useEffect(() => {
        Actions.artistInfoFetch({ ArtistID: Number(id) });
        Actions.relatedArtListFetch({ ArtistID: Number(id), Count: 10 });
        Actions.exhibitEventListFetch({ ArtistID: Number(id), Count: 10 });
        fetchFollowStatus({ UserID: Number(Reducer.userAuth.userId), ContentsType: Constants.FOLLOW_CONTENTS_TYPE_ARTIST, ContentsID: Number(id) });
        return () => {
            Actions.artistInfoFetching(false, false);
            Actions.relatedArtListFetching(false, false);
            Actions.exhibitEventListFetching(false, false);
            Actions.insertFollowContentsFetching(false, false);
            Actions.deleteFollowContentsFetching(false, false);
        }
    }, []);

    function fetchFollowStatus(postData) {
        setFollowStatusFetch(prev => ({ ...prev, loading: true, loadingError: false, statusCode: 0 }));

        fetch(AppConfig.GetFollowStatus, {
            method: "post",
            headers: {
                "x-api-key": apiKey,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw err
                        });
                }
                setFollowStatusFetch(prev => ({ ...prev, loading: false }));
                return response;
            })
            .then(response => response.json())
            .then(status => {
                if (status.FollowStatus) {
                    Actions.addFollowContentsList(postData);
                } else {
                    Actions.deleteFollowContentsList(postData);
                }
            })
            .catch((e) => {
                setFollowStatusFetch(prev => ({ ...prev, loading: false, loadingError: true, statusCode: Number(e.StatusCode) }));
            });
    }

    const followStatus = Reducer.followContentsList.some(x => x.Id == id && x.IsArtist);

    const loadingFlagKeys = ["artistInfoFetch", "relatedArtListFetch", "exhibitEventListFetch", "insertFollowContents", "deleteFollowContents"];
    const loadingFlag = loadingFlagKeys.some(key => Reducer[key].loading);
    const loadingErrorFlag = loadingFlagKeys.some(key => Reducer[key].loadingError);
    const errorMessage =
        loadingErrorFlag || followStatusFetch.loadingError
            ? <Alert severity="error" sx={{ mt: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {loadingFlagKeys.filter(key => Reducer[key].loadingError).map(key =>
                    <>
                        {ApiErrorMessages.apiMessage(Reducer[key].statusCode, "Reducer." + key)}<br />
                    </>
                )}
                {followStatusFetch.statusCode ?
                    ApiErrorMessages.apiMessage(followStatusFetch.statusCode, "ArtistDetail.fetchFollowStatus")
                    : null
                }
            </Alert>
            : null;

    // ローディング表示
    useEffect(() => {
        setTimeout(() => {
            if (loadingFlag) {
                setIsTimeout(true);
            } else {
                setIsTimeout(false)
            }
        }, 3000);
    }, [loadingFlag]);

    return (
        <>
            <Container maxWidth="lg">
                <Loader open={(loadingFlag || followStatusFetch.loading) && isTimeout} />
                {errorMessage}
                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Artist
                    </Typography>
                    {artistInfo
                        ? <DetailInformation
                            artist
                            img={artistInfo.ArtistImage?.ImageObjectURL}
                            title={artistInfo.getArtistDisplayName(Reducer.language)}
                            info={artistInfo.getArtistIntroduction(Reducer.language)}
                            urlList={artistInfo.ArtistURL}
                            followStatus={followStatus}
                        />
                        : null
                    }
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Arts
                    </Typography>
                    {Reducer.relatedArtList.length === 0
                        ? null
                        : <ImageScroller imgCardList={Reducer.relatedArtList} />
                    }
                </Box>
                <Divider />

                <Box component="div" className='titleStyle'>
                    <Typography variant="h3" mb={2}>
                        Events
                    </Typography>
                    {Reducer.exhibitEventList.length === 0
                        ? null
                        : <ImageScroller imgCardList={Reducer.exhibitEventList} />
                    }
                </Box>
            </Container>
        </>
    );
}
